import React from "react";
import Layout from "../components/Layout";
import { last_updated } from '~/src/static/data/home.json';
import {WbLogoBlue, ESMAPLogo, PPIAFLogo} from "../components/Images";

export default function Download() {

  return (
    <Layout>
      <div className="container about-section">
       <header className="mt-4">
          <h3 className="fw-bold">Download</h3>
        </header>

        <p className='w-75'>
          The <a title="Download Indicator Data" href="/static/data/upbeat_global_data_2024.xlsx">Upbeat Indicator data</a>
          {" "} presented in this website is available in an Excel Spreadsheet. The data was last updated on {last_updated}.
      </p>
        <p className='w-75'>
          The data on this website is distributed under the
          {" "}
           <a href='https://creativecommons.org/licenses/by/4.0/' title='CC-BY-4.0'>Creative Commons by Attribution 4.0 license</a>.
          You are free to share and adapt this data provided you give appropriate credit,
          provide a link to the licence, and indicate if changes were made.
        </p>

        <div className="mt-5">
        <img
          alt="World Bank Group Logo"
          src={WbLogoBlue}
          className="img-fluid bottom-logo"
        />
        <img
          alt="ESMAP Logo"
          src={ESMAPLogo}
          className="img-fluid bottom-logo"
        />
        <img
          alt="PPIAF Logo"
          src={PPIAFLogo}
          className="img-fluid bottom-logo"
        />
      </div>
        </div>

    </Layout>
  );
}
