// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { Buffer } from './buffer.js';
import { Int } from './int.js';
import { SparseMatrixCompressedAxis } from './sparse-matrix-compressed-axis.js';


/**
 * Compressed Sparse format, that is matrix-specific.
 */
export class SparseMatrixIndexCSX {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SparseMatrixIndexCSX {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSparseMatrixIndexCSX(bb:flatbuffers.ByteBuffer, obj?:SparseMatrixIndexCSX):SparseMatrixIndexCSX {
  return (obj || new SparseMatrixIndexCSX()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSparseMatrixIndexCSX(bb:flatbuffers.ByteBuffer, obj?:SparseMatrixIndexCSX):SparseMatrixIndexCSX {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SparseMatrixIndexCSX()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

/**
 * Which axis, row or column, is compressed
 */
compressedAxis():SparseMatrixCompressedAxis {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt16(this.bb_pos + offset) : SparseMatrixCompressedAxis.Row;
}

/**
 * The type of values in indptrBuffer
 */
indptrType(obj?:Int):Int|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Int()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

/**
 * indptrBuffer stores the location and size of indptr array that
 * represents the range of the rows.
 * The i-th row spans from `indptr[i]` to `indptr[i+1]` in the data.
 * The length of this array is 1 + (the number of rows), and the type
 * of index value is long.
 *
 * For example, let X be the following 6x4 matrix:
 * ```text
 *   X := [[0, 1, 2, 0],
 *         [0, 0, 3, 0],
 *         [0, 4, 0, 5],
 *         [0, 0, 0, 0],
 *         [6, 0, 7, 8],
 *         [0, 9, 0, 0]].
 * ```
 * The array of non-zero values in X is:
 * ```text
 *   values(X) = [1, 2, 3, 4, 5, 6, 7, 8, 9].
 * ```
 * And the indptr of X is:
 * ```text
 *   indptr(X) = [0, 2, 3, 5, 5, 8, 10].
 * ```
 */
indptrBuffer(obj?:Buffer):Buffer|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Buffer()).__init(this.bb_pos + offset, this.bb!) : null;
}

/**
 * The type of values in indicesBuffer
 */
indicesType(obj?:Int):Int|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Int()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

/**
 * indicesBuffer stores the location and size of the array that
 * contains the column indices of the corresponding non-zero values.
 * The type of index value is long.
 *
 * For example, the indices of the above X is:
 * ```text
 *   indices(X) = [1, 2, 2, 1, 3, 0, 2, 3, 1].
 * ```
 * Note that the indices are sorted in lexicographical order for each row.
 */
indicesBuffer(obj?:Buffer):Buffer|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new Buffer()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSparseMatrixIndexCSX(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addCompressedAxis(builder:flatbuffers.Builder, compressedAxis:SparseMatrixCompressedAxis) {
  builder.addFieldInt16(0, compressedAxis, SparseMatrixCompressedAxis.Row);
}

static addIndptrType(builder:flatbuffers.Builder, indptrTypeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, indptrTypeOffset, 0);
}

static addIndptrBuffer(builder:flatbuffers.Builder, indptrBufferOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, indptrBufferOffset, 0);
}

static addIndicesType(builder:flatbuffers.Builder, indicesTypeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, indicesTypeOffset, 0);
}

static addIndicesBuffer(builder:flatbuffers.Builder, indicesBufferOffset:flatbuffers.Offset) {
  builder.addFieldStruct(4, indicesBufferOffset, 0);
}

static endSparseMatrixIndexCSX(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 6) // indptrType
  builder.requiredField(offset, 8) // indptrBuffer
  builder.requiredField(offset, 10) // indicesType
  builder.requiredField(offset, 12) // indicesBuffer
  return offset;
}

}
