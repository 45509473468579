import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/global.css";
import Navigation from "./Navbar";
import Footer from "./Footer";

export default function LayoutWithInnerSidebar({ children }) {
  return (
    <section id="page-layout-with-sidebar">
      <Navigation />
      <div id="section-with-sidebar">{children}</div>
      {/* <Footer /> */}
    </section>
  );
}
