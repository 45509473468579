import {  useLocation } from 'react-router-dom';
import { useLayoutEffect, useEffect } from 'react';


const ScrollTo = ({children}) => {
  const location = useLocation();
  useEffect(() => {
    if (!document.location.hash) {
      document.documentElement.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash]);
  return children;
};

export { ScrollTo }
