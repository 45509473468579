import { Component } from "react";
import { PropTypes as T } from "prop-types";

import { Group } from "@visx/group";
import { AxisLeft, AxisRight } from "@visx/axis";
import { Text } from "@visx/text";

const tickComponent = ({ formattedValue, ...tickProps }) => (
  <text {...tickProps}>{formattedValue}</text>
);

const yTickProps = () => ({
  textAnchor: "end",
  dx: "-0.25em",
  dy: "0.25em",
  // to match bottom axis
  fontFamily: "Arial",
  fontSize: "12px",
});

class YAxis extends Component {
  render() {
    const {
      xMin,
      yMin = 0,
      yScale,
      label,
      yTickLength = 4,
      numTicks = 3,
      textAnchor = "end",
      labelDx = 0,
      tickFormat = (s)=>s,
    } = this.props;

    return (
      <Group>
        <Text
          verticalAnchor="start"
          textAnchor={textAnchor}
          x={xMin}
          y={yMin}
          dx={labelDx}
          className="chart__yaxis_label"
        >
          {label}
        </Text>
        <AxisLeft
          left={xMin}
          scale={yScale}
          tickComponent={tickComponent}
          className="y-axis"
          tickLabelProps={yTickProps}
          tickLength={yTickLength}
          numTicks={numTicks}
          tickFormat={tickFormat}
        />
      </Group>
    );
  }
}

const rightYTickProps = () => ({
  textAnchor: "start",
  dx: "+0.25em",
  dy: "0.25em",
  // to match bottom axis
  fontFamily: "Arial",
  fontSize: "10px",
});


class RightYAxis extends Component {
  render() {
    const {
      xMin,
      yMin = 0,
      yScale,
      label,
      yTickLength = 4,
      numTicks = 3,
      textAnchor = "end",
      labelDx = 0,
      tickFormat = (s)=>s,
    } = this.props;

    return (
      <Group>
        <Text
          verticalAnchor="start"
          textAnchor={textAnchor}
          x={xMin}
          y={yMin}
          dx={labelDx}
          className="chart__yaxis_label"
        >
          {label}
        </Text>
        <AxisRight
          left={xMin}
          scale={yScale}
          tickComponent={tickComponent}
          className="right-y-axis"
          tickLabelProps={rightYTickProps}
          tickLength={yTickLength}
          numTicks={numTicks}
          tickFormat={tickFormat}
        />
      </Group>
    );
  }
}


export {YAxis, RightYAxis};
