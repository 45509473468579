import WbLogo from "url:../static/img/logos/world-bank-logo-white.png";
import WbLogoBlue from "url:../static/img/logos/WB-horizontal-RGB-high.png";
import SampleGraph from "url:../static/img/sample-graph.png";
import WorldMap from "url:../static/img/world-map.png";
import Continents from "url:../static/img/continents-of-earth.png";
import FinPerfImg from "url:../static/img/financial-perf-graph.png";
import OpPerfImg from "url:../static/img/operational-perf-graph.png";
import ESMAPLogo from "url:../static/img/logos/ESMAP-logo.svg";
import PPIAFLogo from "url:../static/img/logos/PPIAF.png";

export {WbLogo, SampleGraph, WorldMap, Continents, FinPerfImg, OpPerfImg, WbLogoBlue, ESMAPLogo, PPIAFLogo};
