import React, { Component } from "react";
import { iso322 } from "../static/data/iso3";

export const CountryFlags = ({
  iso3,
  width = "30px",
  className,
  style = {},
}) => {
  const iso2 = iso322[iso3];

  if (iso2 == undefined) {
    return "";
  }
  return (
    <img
      src={`/static/flags-4x3/${iso2}.svg`}
      width={width}
      className={className}
      style={style}
    />
  );
};
