/* global Intl */

import countriesCsv from "bundle-text:../static/data/country_names.csv";
import regionsCsv from "bundle-text:../static/data/region_names.csv";
import { parse as csvParse } from "csv-parse/lib/sync";

import { indicatorFormatMapping } from './prefs';

const _cachedIndFmt = Object.fromEntries(
  Object.entries(indicatorFormatMapping).map(([ind,digits])=>
    [ind, Intl.NumberFormat('en-US', {maximumFractionDigits: digits})]));

const parse = (csv, options = {}) =>
  csvParse(csv, { columns: true, cast: true, ...options });

const _reshape = (data) =>
      Object.fromEntries(
          data.map(({ source, display }) => [source, display])
);

const _regions = _reshape(parse(regionsCsv));
const _countries = _reshape(parse(countriesCsv));

const fmtRegion = (r) =>
      _regions[r] || r;

const fmtCountry = (c) =>
      _countries[c] || c;

const _neg0 = (s) =>
      s == '-0' ? 0 : s;

const fmtIndicator = (v,ind) => {
  return (_cachedIndFmt[ind] ? _neg0(_cachedIndFmt[ind].format(v)) :
          v.toLocaleString('en-US', { maximumFractionDigits: 3}));

}
const fmtGrid = (data) =>
        data.map((row)=>Object.fromEntries(Object.entries(row).map(([k,v])=>
          typeof(v) === 'number' ? [k, fmtIndicator(v, row.indicator)] : [k,v]
        )));

console.log(indicatorFormatMapping);


const _utilTypes = {
  'VIU': 'Vertically Integrated Utility',
  'D': 'Distribution',
  'G': 'Generation',
  'T': 'Transmission',
  'T&D': 'Transmission and Distribution',
  'G&T': 'Generation and Transmission',
  'G&D': 'Generation and Distribution',
};

const fmtUtilType = (s) => _utilTypes[s] || s;


export {fmtCountry, fmtRegion, fmtIndicator, fmtGrid, fmtUtilType};
