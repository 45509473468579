// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

/**
 * Same as Binary, but with 64-bit offsets, allowing to represent
 * extremely large data values.
 */
export class LargeBinary {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):LargeBinary {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsLargeBinary(bb:flatbuffers.ByteBuffer, obj?:LargeBinary):LargeBinary {
  return (obj || new LargeBinary()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsLargeBinary(bb:flatbuffers.ByteBuffer, obj?:LargeBinary):LargeBinary {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new LargeBinary()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static startLargeBinary(builder:flatbuffers.Builder) {
  builder.startObject(0);
}

static endLargeBinary(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createLargeBinary(builder:flatbuffers.Builder):flatbuffers.Offset {
  LargeBinary.startLargeBinary(builder);
  return LargeBinary.endLargeBinary(builder);
}
}
