"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = constants;
function constants(x) {
  return function () {
    return x;
  };
}