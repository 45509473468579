// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { IntervalUnit } from './interval-unit.js';


export class Interval {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Interval {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsInterval(bb:flatbuffers.ByteBuffer, obj?:Interval):Interval {
  return (obj || new Interval()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsInterval(bb:flatbuffers.ByteBuffer, obj?:Interval):Interval {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Interval()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

unit():IntervalUnit {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt16(this.bb_pos + offset) : IntervalUnit.YEAR_MONTH;
}

static startInterval(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addUnit(builder:flatbuffers.Builder, unit:IntervalUnit) {
  builder.addFieldInt16(0, unit, IntervalUnit.YEAR_MONTH);
}

static endInterval(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createInterval(builder:flatbuffers.Builder, unit:IntervalUnit):flatbuffers.Offset {
  Interval.startInterval(builder);
  Interval.addUnit(builder, unit);
  return Interval.endInterval(builder);
}
}
