import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/global.css";
import Navigation from "./Navbar";
import Footer from "./Footer";

export default function Layout({ children, customClassName }) {
  const combinedClassName = `content ${customClassName || ''}`;

  return (
    <section id="page-layout">
      <Navigation />
      <div id="content" className={combinedClassName}>{children}</div>
      <Footer />
    </section>
  );
}
