import React, { useState, useEffect } from 'react';
import {NavLink} from "react-router-dom";

export default function CounterCard({ linkUrl, iconSrc, count, label }) {
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentCount < count) {
          setCurrentCount((prevCount) => prevCount + 1);
        } else {
          clearInterval(interval);
        }
      }, 10);
  
      return () => clearInterval(interval);
    }, [currentCount, count]);
  
    return (
        <div className="clickable-box-card">
          <div className='clickable-container'>
          <div className="d-flex gap-3 stats-container">
            <img src={iconSrc} className="stats-icon" alt={`${label} Icon`} />
            <article>
              <h1 className="fw-light">{currentCount}</h1>
              <h6 className="fw-bold">{label}</h6>
            </article>
          </div>
        </div>
        </div>
    );
  };
