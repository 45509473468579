import { createRoot } from "react-dom/client";
//import { App } from "./App";

import {DataLayer} from "./DataLayer";
import {DataContext} from "./components/DataContext";

import {
  Navigate,
  BrowserRouter,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Home from './pages/Home';
import About from './pages/about';
import NotFound from './pages/NotFound';
import Indicators from './pages/indicators';
import UtilitiesByCountry from './pages/utilities-by-country';
import Dashboard from './pages/dashboard';
import UtilitiesGrid from "./pages/utilities-grid";
import Download from "./pages/download";

import {ScrollTo } from "./components/ScrollTo";

const PassRouteParams = ({ to: Component }) => {
  const params = useParams();
  return <Component {...params} />;
};

const datalayer = new DataLayer();
datalayer.init_db();

const defaultIndicator='cost-of-debt';

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <DataContext.Provider value={datalayer} >
      <ScrollTo>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Navigate to="/" />} />
          <Route
            path="/indicators"
            element={<Navigate to={`/indicators/${defaultIndicator}`} />}
          />
          <Route
            path="/indicators/"
            element={<Navigate to={`/indicators/${defaultIndicator}`} />}
          />
          <Route
            path="/indicators/:indicatorSlug/:grid?"
            element={<PassRouteParams to={Indicators} />}
          />
          <Route
            path="/utilities/"
            element={<UtilitiesByCountry />}
          />
          <Route
            path="/utilities/:utility"
            element={<PassRouteParams to={Dashboard} />}
          />
          <Route
            path="/utilities/:utility/grid"
            element={<PassRouteParams to={UtilitiesGrid} />}
          />
          <Route path="/about"
                 element={<Navigate to="/about/intro" />}
          />
          <Route path="/about/"
                 element={<Navigate to="/about/intro" />}
          />
          <Route
            path="/about/:page"
            element={<PassRouteParams to={About} />}
          />
          <Route
            path="/download/"
            element={<PassRouteParams to={Download} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollTo>
    </DataContext.Provider>
  </BrowserRouter>
);
