/* global Worker */

import * as duckdb from "@duckdb/duckdb-wasm";
import duckdb_wasm from "url:@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm";
import mvp_worker from "url:@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js";
import duckdb_wasm_eh from "url:@duckdb/duckdb-wasm/dist/duckdb-eh.wasm";
import eh_worker from "url:@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js";

const MANUAL_BUNDLES = {
  mvp: {
    mainModule: duckdb_wasm,
    mainWorker: mvp_worker,
  },
  eh: {
    mainModule: duckdb_wasm_eh,
    mainWorker: eh_worker,
  },
};
let _db,
  _initialized = false;

async function init() {
  if (_initialized) return;
  _initialized = true;
  let db;
  try {
    // Select a bundle based on browser checks
    const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
    // Instantiate the asynchronus version of DuckDB-wasm
    const worker = new Worker(bundle.mainWorker);
    const logger = new duckdb.ConsoleLogger();
    db = new duckdb.AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker);

    const conn = await db.connect();
    await conn.query("SELECT 1;");
    await conn.close();
  } catch (e) {
    console.log("Error instantiating duckdb");
    console.error("Error instantiating duckdb", e);
    return;
  }
  _db = db;
}

const getDB = async () => {
  await init();
  return _db;
};
export default { getDB, ...duckdb };
