import React, { useMemo, useContext, useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NotFound from "./NotFound";
import { CountryFlags } from "../components/CountryFlags";
import Layout from "../components/Layout";
import ContentSidebar from "../components/ContentSidebar";
import ContentMainPage from "../components/ContentMainPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar, faShuffle, faGrip } from "@fortawesome/free-solid-svg-icons";

import {DataContext} from "../components/DataContext";
import {PerformanceChart} from "../components/PerformanceChart";
import {DashboardChartWithLegend} from "../components/DashboardChart";

import { iso3ForCountry } from "../static/data/iso3";
import {fmtCountry, fmtRegion, fmtIndicator, fmtUtilType} from "../components/format";

import { dashboardIndicatorsList, graphsIndicatorsPrefs } from '../components/prefs';
// import nestByRegionCountry from './utilities-by-country'



import {
  NavLink,
} from "react-router-dom";

import { FinPerfImg, OpPerfImg } from "../components/Images";


// │ D            │
// │ G&T          │
// │ VIU          │
// │ G            │
// │ G&D          │
// │ T&D          │
// │ T            │


const distUtilsTypes = ['D', 'G&D', 'T&D', 'VIU'];
const transUtilsTypes = ['G&T', 'T', 'T&D', 'VIU'];
const sysUtilsTypes = ['VIU', 'T&D'];

const indicatorUtilTypes = {
  "SAIDI-D": distUtilsTypes,
  "SAIDI-T": transUtilsTypes,
  "SAIFI-D": distUtilsTypes,
  "SAIFI-T": transUtilsTypes,
  "System losses": sysUtilsTypes,
  "Distribution losses": distUtilsTypes,
  "Transmission losses": transUtilsTypes,
};

const tpFilter = (l, utilType) =>
      l && l.filter((e)=>indicatorUtilTypes[e].includes(utilType));

const indicatorList = {
  financial: {
    bar: [
      "Operating cost recovery, excluding subsidies",
      "Operating and debt service cost recovery, including subsidies",
      "Operating and debt service cost recovery, excluding subsidies",
    ],
    line: [
      "Collection rate",
    ],
  },
  operational: {
    bar: [
      "SAIDI-D",
      "SAIDI-T",
    ],
    line: [
      "SAIFI-D",
      "SAIFI-T",
    ],
  },
  losses: {
    line: [
      "Distribution losses",
      "Transmission losses",
      "System losses",
    ],
  },
};

const graphIndicatorList = Object.values(indicatorList).map((v)=>Object.values(v)).flat(2);


export default function Dashboard({utility}) {
  const dataLayer = useContext(DataContext);

  const [data, setData] = useState([]);
  const [db_init, setDbInit] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [utilityInfo, setUtilityInfo] = useState({});
  const [graphIndicators, setGraphIndicators] = useState([]);
  const [medians,setMedians] = useState([]);
  const [utilities, setUtilities] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!db_init) {
      if (dataLayer.isDbInit()) {
        console.log('already inited');
        setDbInit(true);
      } else {
        console.log('calling into datalayer.init_db');
        dataLayer.init_db(setDbInit, undefined);
        return;
      }
    }

    const _d = async () => {
      console.log('getting basic info');
      const sql = 'select country, utility_display, utility_short_display, utility_type, ownership from data where utility=? limit 1';
      const _data = await dataLayer.query(sql, [utility]);
      console.log(_data[0]);
      if (!_data) {
        setNotFound(true);
      }
      setUtilityInfo(_data[0]);
    };

    const _f = async () => {
      console.log('getting utilitydashboarddata');
      const _data = await dataLayer.getUtilityDashboardData(utility);
      console.log(_data[0]);
      if (!_data) {
        setNotFound(true);
      }
      const reshaped = Object.fromEntries(_data.map((e) => [e.indicator, e]));
      setData(reshaped);
    };

    const _g = async() => {
      console.log('getting indicator data');
      const _data = await dataLayer.getUtilityIndicatorData(utility, graphIndicatorList);
      setGraphIndicators(_data);
    };

    const _h = async() => {
      console.log('getting median data');
      const _data = await dataLayer.getDashboardIndicatorMedians();
      setMedians(Object.fromEntries(_data.map((e)=> [e.indicator, {m10: e.medians.get(0), m90:e.medians.get(1)}])));
    };

    const _util = async () => {
      const _util_data = await dataLayer.query(`
          select distinct country, utility, utility_display
           from data
           order by country, utility`, []);
      if (_util_data){
        setUtilities(_util_data);
      }
    };

    _d().then(_f).then(_g).then(_h).then(_util);

    }, [utility, db_init, notFound]);


  if (notFound) {
    return (<NotFound/>);
  }


  let topLayout = [];
  const halfLen = Math.floor(dashboardIndicatorsList.length/2);
  for (var i=0; i<halfLen; i++) {
    topLayout.push([dashboardIndicatorsList[i].indicator_display,
                    dashboardIndicatorsList[i+halfLen].indicator_display]);
  }

  const _m10 = (i) =>
        medians[i] ? fmtIndicator(medians[i].m10,i) : 'N/A';

  const _m90 = (i) =>
        medians[i] ? fmtIndicator(medians[i].m90,i) : 'N/A';

  const IndicatorCell = ({indicator, year, value, display_unit}) =>
        year ? (<>
      <td>{indicator} ({year})</td>
      <td>{fmtIndicator(value, indicator)} {display_unit}</td>
      <td>{_m10(indicator)} - {_m90(indicator)}</td>
    </>) : (
      <>
        <td className="nodata">{indicator}</td>
        <td className="nodata">No Data</td>
        <td></td>
      </>);

  const IndicatorTable2Col = ({layout, indicators}) => {
    return (
      <table className="utility-table">
        <thead >
          <tr>
            <th scope="col">Indicator</th>
            <th scope="col">Value</th>
            <th scope="col">10th-90th Percentile Range</th>
            <th scope="col">Indicator</th>
            <th scope="col">Value</th>
            <th scope="col">10th-90th Percentile Range</th>
          </tr>
        </thead>
        <tbody>
          {layout.map(([ind1, ind2],ix) => {
            const _ind1 = indicators[ind1] || {};
            const _ind2 = indicators[ind2] || {};
            return (
              <tr key={ix}>
                <IndicatorCell
                  key={`indicator-${ix}`}
                  {..._ind1}
                  indicator={ind1}
                />
                <IndicatorCell
                  key={`indicator-${ix*2+1}`}
                  {..._ind2}
                  indicator={ind2}
                />
              </tr>);
          })}
        </tbody>
      </table>
    );
  };

  const IndicatorTable1Col = ({layout, indicators}) => {
    return (
      <table className="utility-table">
        <thead >
          <tr>
            <th scope="col">Indicator</th>
            <th scope="col">Value</th>
            <th scope="col">10th-90th Percentile Range</th>
          </tr>
        </thead>
        <tbody>
          {layout.map((ind,ix) => {
            const _ind1 = indicators[ind] || {};
            return (
              <tr key={ix}>
                <IndicatorCell
                  key={`indicator-${ix}`}
                  {..._ind1}
                  indicator={ind}
                />
              </tr>);
          })}
        </tbody>
      </table>
    );
  };

  const handleChange = (event) => {
    const utility = event.target.value;
    if (utility !== "") {
      navigate(`/utilities/${utility}`);
    }
  };

  const options =  utilities.map(e => (
        <option key={e.utility} value={e.utility}>
          {e.country} - {e.utility_display}
        </option>
  ));

  return (
    <Layout customClassName="utility-content-section">
      <aside className="mt-4 mb-5">
        <div className="d-flex justify-content-between">
          <div>
            <NavLink
              to={"/"}
              className="text-decoration-none fw-light text-black ps-1 pe-1"
            >
              Home
            </NavLink>
            /
            <NavLink
              to={"/utilities/"}
              className="text-decoration-none fw-light text-black ps-1 pe-1"
            >
              Utilities
            </NavLink>
            / {utilityInfo.utility_short_display}
          </div>

          <div className="w-50">
            <select onChange={handleChange} className="form-select">
              <option value="">-- Select Utility --</option>
              {options}
            </select>
          </div>
        </div>
      </aside>



      {utilityInfo && data ? (
        <>
          <section id="selection-made">
            <div className="row">
              <div className="d-flex">
                <div>
                <CountryFlags
                  iso3={iso3ForCountry(utilityInfo.country)}
                  style={{
                    marginRight: "2rem",
                    width: "7rem",
                    border: "1px solid #aaa",
                  }}
                />
                </div>
                <div>
                  <h1 className="fw-light">{fmtCountry(utilityInfo.country)}</h1>
                  <h5 className="text-navy-blue fw-bold extra-text-spacing">{utilityInfo.utility_display} ({utilityInfo.utility_short_display})</h5>
                  <div className="d-flex gap-4 mt-3">
                    <h6 className="text-navy-blue extra-text-spacing">
                      <span className="fw-bold">Utility type:</span> {fmtUtilType(utilityInfo.utility_type)}</h6>
                    <h6 className="text-navy-blue extra-text-spacing">
                      <span className="fw-bold">Ownership:</span> {utilityInfo.ownership}</h6>
                  </div>
                </div>
              </div>
            </div>

            <section className="mt-5 mb-4">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="text-black fw-bold">Scope of the Utility's Activities</h5>
                    <div className="d-flex justify-content-end mb-3 gap-3">
                      <NavLink
                        to={`/utilities/${utility}/grid`}
                        className="btn btn-yellow"
                      >
                        <>
                          Table View
                          <img src="/static/img/Grid.svg" className="ms-2 btn-icons" />
                        </>
                      </NavLink>
                      <a
                        className="btn btn-navy-blue"
                        title="Download as CSV"
                        onClick={e=>dataLayer.csvDownloadForUtility(utility)}
                      >
                        Download CSV <FontAwesomeIcon icon={faGrip} className="ms-2" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <IndicatorTable2Col
                    layout={topLayout}
                    indicators={data}
                  />
                </div>
              </div>
            </section>

            <section>
              <div className="row utility-graph-section">
                <div className="col-12 dashboard-subtitles">
                   <h5 className="text-black fw-bold">Financial Performance Over Time</h5>
                </div>
                <div className="col col-with-br perf-section">
                  <DashboardChartWithLegend
                    data={graphIndicators}
                    barIndicators={indicatorList.financial.bar}
                    lineIndicators={indicatorList.financial.line}
                    prefs={{display_unit:'%'}}
                  />
                </div>
                <div className="col">
                  <IndicatorTable1Col
                    layout = {["Operating cost recovery, excluding subsidies",
                               "Operating and debt service cost recovery, including subsidies",
                               "Operating and debt service cost recovery, excluding subsidies",
                               "Collection rate",
                               "Age of payables",
                               "Age of receivables",
                               "Current ratio",
                               "Debt to assets ratio",
                               "Maturity matching ratio",
                               "Net profit margin",
                              ]}
                    indicators = {data}
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-12 dashboard-subtitles">
                <h5 className="text-black fw-bold">Reliability Over Time</h5>
              </div>
                <div className="col col-with-br perf-section">
                  <DashboardChartWithLegend
                    data={graphIndicators}
                    barIndicators={tpFilter(indicatorList.operational.bar, utilityInfo.utility_type)}
                    lineIndicators={tpFilter(indicatorList.operational.line, utilityInfo.utility_type)}
                    prefs={{display_unit:'Hours/year',
                            right_display_unit:'Interruptions/customer/year'
                           }}
                  />
                </div>
                <div className='col'>
                  <IndicatorTable1Col
                    layout = {tpFilter(["SAIDI-D",
                                        "SAIDI-T",
                                        "SAIFI-D",
                                        "SAIFI-T",
                                       ], utilityInfo.utility_type)}
                    indicators = {data}
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-12 dashboard-subtitles">
                <h5 className="text-black fw-bold">Losses Over Time</h5>
              </div>
                <div className="col col-with-br perf-section">
                  <DashboardChartWithLegend
                    data={graphIndicators}
                    barIndicators={tpFilter(indicatorList.losses.bar, utilityInfo.utility_type)}
                    lineIndicators={tpFilter(indicatorList.losses.line, utilityInfo.utility_type)}
                    prefs={{display_unit:'%'}}
                  />
                </div>
                <div className='col'>
                  <IndicatorTable1Col
                    layout = {tpFilter(["System losses",
                                        "Distribution losses",
                                        "Transmission losses",
                                       ], utilityInfo.utility_type) }
                    indicators = {data}
                  />
                </div>
              </div>
            </section>
          </section>
        </>) : "" }
    </Layout>
  );
}
