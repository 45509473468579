// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { Buffer } from './buffer.js';
import { TensorDim } from './tensor-dim.js';
import { Type } from './type.js';


export class Tensor {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Tensor {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTensor(bb:flatbuffers.ByteBuffer, obj?:Tensor):Tensor {
  return (obj || new Tensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTensor(bb:flatbuffers.ByteBuffer, obj?:Tensor):Tensor {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Tensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

typeType():Type {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : Type.NONE;
}

/**
 * The type of data contained in a value cell. Currently only fixed-width
 * value types are supported, no strings or nested types
 */
type(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

/**
 * The dimensions of the tensor, optionally named
 */
shape(index: number, obj?:TensorDim):TensorDim|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new TensorDim()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

shapeLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

/**
 * Non-negative byte offsets to advance one value cell along each dimension
 * If omitted, default to row-major order (C-like).
 */
strides(index: number):bigint|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : BigInt(0);
}

stridesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

/**
 * The location and size of the tensor's data
 */
data(obj?:Buffer):Buffer|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new Buffer()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startTensor(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addTypeType(builder:flatbuffers.Builder, typeType:Type) {
  builder.addFieldInt8(0, typeType, Type.NONE);
}

static addType(builder:flatbuffers.Builder, typeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, typeOffset, 0);
}

static addShape(builder:flatbuffers.Builder, shapeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, shapeOffset, 0);
}

static createShapeVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startShapeVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addStrides(builder:flatbuffers.Builder, stridesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, stridesOffset, 0);
}

static createStridesVector(builder:flatbuffers.Builder, data:bigint[]):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt64(data[i]!);
  }
  return builder.endVector();
}

static startStridesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addData(builder:flatbuffers.Builder, dataOffset:flatbuffers.Offset) {
  builder.addFieldStruct(4, dataOffset, 0);
}

static endTensor(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 6) // type
  builder.requiredField(offset, 8) // shape
  builder.requiredField(offset, 12) // data
  return offset;
}

static finishTensorBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedTensorBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

}
