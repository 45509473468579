import React, {useState, useEffect} from "react";
import LayoutWithInnerSidebar from "../components/LayoutWithInnerSidebar";
import ContentSidebar from "../components/ContentSidebar";
import ContentMainPage from "../components/ContentMainPage";
import { NavLink } from "react-router-dom";
import {WbLogoBlue, ESMAPLogo, PPIAFLogo} from "../components/Images";

const pages = {
  "about": {
      path: "intro",
      title: "Introduction",
    },
  "indicators": {
    path: "indicators",
    title: " Indicator definitions",
  },
  contact: {
    path: "contact",
    title: "Contact",
  },
};


export default function About({page}) {
  const  [body, setBody ] = useState('');
  console.log(page);

  useEffect(() => {
    fetchHtmlContent(page || 'about');
  }, [page]);

  const fetchHtmlContent = async (path) => {
    try {
      const response = await fetch(`/static/html/${path}.html`);
      const htmlContent = await response.text();
      setBody(htmlContent);
    } catch (error) {
      console.error('Error fetching HTML content:', error);
    }
  };

  return (
    <LayoutWithInnerSidebar>
      <ContentSidebar>
        <header className="ps-4 pe-4 mt-4">
          <h3 className="fw-bold">
            About <br /> <span>UPBEAT</span>
          </h3>
        </header>

        <ul className="inner-navs list-decoration-none mt-5">
          {Object.values(pages).map((e) => (
            <li key={e.path}>
              <NavLink
                to={`/about/${e.path}`}
                className={({ isActive }) =>
                  `inner-nav-items nav-link ${isActive ? 'active' : ''}`
                }
              >
                <span>{e.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </ContentSidebar>
      <ContentMainPage>

        <article className="">
          <div className="about-section" dangerouslySetInnerHTML={{ __html: body }} />
        </article>

        <section className="bottom-logos">
        <img
          alt="World Bank Group Logo"
          src={WbLogoBlue}
          className="img-fluid bottom-logo"
        />
        <img
          alt="World Bank Group Logo"
          src={ESMAPLogo}
          className="img-fluid bottom-logo"
        />
        <img
          alt="World Bank Group Logo"
          src={PPIAFLogo}
          className="img-fluid bottom-logo"
        />
      </section>
      </ContentMainPage>
    </LayoutWithInnerSidebar>
  );
}
