// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { Precision } from './precision.js';


export class FloatingPoint {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FloatingPoint {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFloatingPoint(bb:flatbuffers.ByteBuffer, obj?:FloatingPoint):FloatingPoint {
  return (obj || new FloatingPoint()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFloatingPoint(bb:flatbuffers.ByteBuffer, obj?:FloatingPoint):FloatingPoint {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FloatingPoint()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

precision():Precision {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt16(this.bb_pos + offset) : Precision.HALF;
}

static startFloatingPoint(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addPrecision(builder:flatbuffers.Builder, precision:Precision) {
  builder.addFieldInt16(0, precision, Precision.HALF);
}

static endFloatingPoint(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createFloatingPoint(builder:flatbuffers.Builder, precision:Precision):flatbuffers.Offset {
  FloatingPoint.startFloatingPoint(builder);
  FloatingPoint.addPrecision(builder, precision);
  return FloatingPoint.endFloatingPoint(builder);
}
}
