/* global MutationObserver */
import React, { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import { CountryFlags } from "../components/CountryFlags";
import { iso3ForCountry } from "../static/data/iso3";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink, NavHashLink } from 'react-router-hash-link';

import {fmtCountry, fmtRegion} from "../components/format";
import {DataContext} from "../components/DataContext";

function nestByRegionCountry(data) {
  const nested = {};

  data.forEach(item => {
    const {region, country} = item;

    if (!nested[region]) {
      nested[region] = {};
    }

    if (!nested[region][country]) {
      nested[region][country] = [];
    }

    nested[region][country].push(item);
  });

  return nested;
}


export default function UtilitiesByCountry() {
  const dataLayer = useContext(DataContext);

  const [utilities, setUtilities] = useState([]);

  const [db_init, setDbInit] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!db_init) {
      if (dataLayer.isDbInit()) {
        setDbInit(true);
      } else {
        dataLayer.init_db(setDbInit);
        return;
      }
    }

    const _util = async () => {
      const _data = await dataLayer.query(`
          select distinct region, country, utility, utility_short_display, utility_display
           from data
           order by region, country, utility`, []);
      if (_data){
        setUtilities(nestByRegionCountry(_data));
      }
    };

    _util();

  }, [dataLayer, db_init]); // Fix: Add dependencies to the array

  const hash = location.hash.replace('#', '');

  // hash target isn't available in a use-effect callback, because it's the result
  // of the callback that's creating the target node. So we're adding a mutation observer
  // that will watch the tree for changes, and when the target does show, we scroll
  // there and then disconnect the observer to make sure it's a oneshot.
  // This of course doesn't work natively because the id isn't there when we load the page.
  useEffect(() => {
    if (!location.hash) { return; }
    const parentElt = document.getElementById('utilityList');
    if (!parentElt) {return;}

    const observer = new MutationObserver((mutList, observer) => {
      const elt = document.getElementById(hash);
      if (! elt) { return; }
      elt.scrollIntoView();
      observer.disconnect();
    });

    observer.observe(parentElt, {childList:true, subtree:true});
  }, [utilities, location.hash]);



  return (
    <Layout customClassName="content-utility">
       <header className="ps-4 pe-4 mt-4 text-center">
          <h3 className="fw-bold">Explore Utilities by Country</h3>
          <p>Select any utility from the list of countries to explore utilities</p>
        </header>

      <div className="sticky-navbar-wrapper">
        <div className="sticky-navbar">
          {Object.entries(utilities).map(([region]) => (
            <HashLink
              key={`nav-${region}`}
              to={`#${region}`}
              className={"text-black " + (hash == region ? 'active': '')}
            >
             {fmtRegion(region)}
            </HashLink>
          ))}
        </div>
      </div>

        <section id='utilityList' className="ps-5 pe-5">
        {Object.entries(utilities).map(([region, countries]) => (
          <div
            key={region}
            id={region}
            className='scrollTarget'
          >
          <h4 className="fw-bold region-title">{fmtRegion(region)}</h4>
          <div className="row">
            {Object.entries(countries).map(([country, utilList]) => (
              <div key={country} className="col-3">
                <div className="country-flag-section">
                  <div>
                <CountryFlags
                  iso3={iso3ForCountry(country)}
                        style={{
                          border: "1px solid #aaa",
                          marginRight: "1rem",
                          width: "3rem",
                        }} />
                        </div>
                  <h5 className="align-self-center">{fmtCountry(country)}</h5>
                </div>
                <ul className="utility-ul">
                  {utilList.map((item, index) => (
                    <li key={index} className="utility-list">
                      <NavLink
                        to={`/utilities/${item.utility}`}
                        className="text-decoration-none text-dark"
                      >
                        <>
                          {item.utility_display} ({item.utility_short_display})
                        </>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
        </section>
    </Layout>
  );
}
