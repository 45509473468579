import React from "react";
import {NavLink} from "react-router-dom";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEarthEurope,
  faNewspaper,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import {WbLogo, WbLogoBlue, ESMAPLogo, PPIAFLogo} from "../components/Images";
import CounterCard from "../components/CounterCard";
import { DetailChart, RevDetailChart } from "../components/DetailChart";

import { stats, medians, gt, pct, data } from '~/src/static/data/home.json';

const _fmt = (n, d=1) => n.toLocaleString(undefined,{maximumFractionDigits:d});

export default function Home() {
  const graphStyle = {height:"160px", width:"160px", margin:"auto", transform: "rotate(0.25turn)"};

  return (
    <Layout customClassName="bg-navy-blue-light">
      <section id="hero" className="text-white bg-navy-blue pt-5 pe-5 ps-5">
        <img
          alt="World Bank Group Logo"
          src={WbLogo}
          className="img-fluid landing-wb-logo"
        />
        <header className="mt-3">
          <h4 className="text-uppercase text-white">Welcome to</h4>
          <h1 className="fw-bolder text-yellow">UPBEAT</h1>
        </header>
        <article className="mt-3">
          <h3 className="text-white">Utility Performance and Behavior Today</h3>
        </article>
      </section>

      <section id="stats">
        <div className="container-alt">
          <div className="row">
            <div className="col">
              <NavLink
                    to="/indicators/"
                    className="text-decoration-none text-navy-blue"
                  >
                <div className="bg-yellow box-card-alt border-white">
                  <header>
                    <div className="d-flex justify-content-between">
                      <h5 className="fw-bold">
                      Indicators
                      </h5>
                      <img src="../static/img/Financial.svg" className="others" />
                    </div>
                  </header>
                  <p>Explore data by indicators</p>
                </div>
              </NavLink>
            </div>

            <div className="col">
              <NavLink
                    to="/utilities/"
                    className="text-decoration-none text-white"
                  >
                <div
                  className="col bg-navy-blue text-white border-white box-card-alt"
                >
                  <header>
                    <div className="d-flex justify-content-between">
                      <h5 className="fw-bold text-white">
                        Utilities
                      </h5>
                      <img src="../static/img/Globe White.svg" className="others" />
                    </div>
                  </header>
                  <p> Explore data by all utilities</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4 mb-4">
        <div className="container-alt">
          <div id="explore-utilities-section">
            <div id="explore-utilities" className="box-card">
              <header className="mb-3">
                <h5 className="fw-bold text-navy-blue">Indicator Preview</h5>
              </header>

              <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xl-4 g-3">
                <NavLink
                  to='/indicators/transmission-losses'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Transmission losses']}
                        medianVal={medians['Transmission losses']}
                        medianStyle={'line'}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">The median transmission loss is {_fmt(medians['Transmission losses'])}%</p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/distribution-losses'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Distribution losses']}
                        medianVal={medians['Distribution losses']}
                        medianStyle={'line'}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">The median distribution loss is {_fmt(medians['Distribution losses'])}%</p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/cost-of-debt'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Cost of debt']}
                        medianVal={medians['Cost of debt']}
                        medianStyle={'line'}
                        limitMax={25}
                        limitMin={0}

                      />
                    </div>
                    <p className="mt-2">The median effective interest paid by utilities is {_fmt(medians['Cost of debt'])}%</p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/current-ratio'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Current ratio']}
                        medianVal={1}
                        highlightThreshold={1}
                        limitMax={5}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">{_fmt(pct['Current ratio'],0)}% of utilities have a current ratio {'>'} 1</p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/operating-and-debt-service-cost-recovery-excluding-subsidies'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Operating and debt service cost recovery, excluding subsidies']}
                        highlightThreshold={100}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">{_fmt(pct['Operating and debt service cost recovery, excluding subsidies'])}% of utilities
                      are able to meet their operating and debt service costs.
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/operating-cost-recovery-excluding-subsidies'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Operating cost recovery, excluding subsidies']}
                        highlightThreshold={100}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">{_fmt(pct['Operating cost recovery, excluding subsidies'])}% of utilities
                      are able to meet their operating costs.
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/net-profit-margin'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <DetailChart
                        data={data['Net profit margin']}
                        medianVal={0}
                        highlightThreshold={0}
                        limitMax={50}
                        limitMin={-50}
                      />
                    </div>
                    <p className="mt-2">{_fmt(pct['Net profit margin'],0)}% of utilities post a net profit</p>
                  </div>
                </NavLink>
                <NavLink
                  to='/indicators/age-of-receivables'
                  style={{color:"inherit", textDecoration:"none"}}
                  className="home-graph"
                  title="Indicator Detail"
                >
                  <div className="col">
                    <div className="border" style={graphStyle}>
                      <RevDetailChart
                        data={data['Age of receivables']}
                        highlightThreshold={60}
                        limitMax={500}
                        limitMin={0}
                      />
                    </div>
                    <p className="mt-2">{_fmt(100-pct['Age of receivables'],0)}% of utilities have receivables less than 60 days of revenue.
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>

            <CounterCard id="stats-country" linkUrl="/utilities" iconSrc="../static/img/Countries.svg" count={stats.country} label="Countries" />
            <CounterCard id="stats-utility"  linkUrl="/utilities" iconSrc="../static/img/Utilities.svg" count={stats.utility} label="Utilities" />
            <CounterCard id="stats-indicator"  linkUrl="/indicators" iconSrc="../static/img/Indicators.svg" count={stats.indicator} label="Indicators" />
            <CounterCard id="stats-year"  linkUrl="/indicators" iconSrc="../static/img/Years of Data.svg" count={stats.year} label="Years of Data" />
          </div>
        </div>
      </section>

      <section className="bottom-logos">
        <img
          alt="World Bank Group Logo"
          src={WbLogoBlue}
          className="img-fluid bottom-logo"
        />
        <img
          alt="World Bank Group Logo"
          src={ESMAPLogo}
          className="img-fluid bottom-logo"
        />
        <img
          alt="World Bank Group Logo"
          src={PPIAFLogo}
          className="img-fluid bottom-logo"
        />
      </section>
    </Layout>
  );
}
