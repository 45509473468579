/* global Intl */
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import {useFetch} from './hooks';

import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { GridRows } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { YAxis, RightYAxis} from "./YAxis";
import { withParentSize } from '@visx/responsive';
import { withTooltip, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { BarGroup, LinePath }  from '@visx/shape';
import { MarkerCircle } from '@visx/marker';
import { LegendOrdinal } from '@visx/legend';

import {fmtCountry, fmtRegion, fmtIndicator} from "../components/format";

import { schemeCategory10 } from 'd3-scale-chromatic';


let tooltipTimeout;
const radius = 3;

const _formatter = Intl.NumberFormat('en-US', {maximumSignificantDigits: 3});
const fmt = x => _formatter.format(x);


// UNDONE:
//   * legend
//   * left/right axis
//   * Correct units
//   * Graph limits
//   * Filter the indicators based on what we're showing.

const _DashboardChart = ({
  parentWidth,
  parentHeight,
  hideTooltip,
  showTooltip,
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  barIndicators=[],
  lineIndicators=[],
  filteredData=[],
  colorScaleBar,
  colorScaleLine,
  prefs,
}) => {


  const svgRef = useRef(null);

  const two_axis = prefs.right_display_unit !== undefined;

  const yearMax =  Math.max(...filteredData.map(e => e.year));
  const yearMin =  Math.min(...filteredData.map(e => e.year));

  const logRound = (e) => {
    const _exp = Math.pow(10, Math.floor(Math.log10(e)));
    return Math.ceil(e/_exp)*_exp;
  };

  const dataMax = (indicators) =>
        logRound(Math.max(...filteredData.map(e => indicators.map((ind) =>e[ind])).flat().filter((e)=>e!==undefined)));

  const displayDataMin = 0;

  const years = filteredData.length ? [...Array(yearMax-yearMin).keys()].map(e=>e+yearMin) : [];

  const yScale = scaleLinear( {
    domain: [displayDataMin,
             dataMax(two_axis ? barIndicators : [...barIndicators, ...lineIndicators])]
  });


  let rightYScale, right_display_unit;
  if (two_axis) {
      rightYScale = scaleLinear( {
        domain: [displayDataMin,
                 dataMax(lineIndicators)]
      });
    right_display_unit = prefs.right_display_unit;
  } else {
    rightYScale = yScale;
    right_display_unit = prefs.display_unit;
  }

  const xScale = scaleLinear( {
    domain: [yearMin - 0.5, yearMax + 0.5],
  });

  const indicatorScale = scaleBand({
    domain: barIndicators,
    padding: 0.2,
  });

  const xMin = 35;
  const xMax = parentWidth-(two_axis ? 35 : 10);
  const yMin = parentHeight-37;
  const yMax = 25;
  const xTickLength = 3;
  const width = xMax-xMin;
  const height = yMin-yMax;

  xScale.range([xMin, xMax]);
  yScale.range([yMin, yMax]);
  rightYScale.range([yMin, yMax]);

  const colWidth = xScale(yearMax) - xScale(yearMax-1);
  const groupWidth = colWidth*0.7;
  indicatorScale.range([0, groupWidth]);

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleMouseMove = useCallback((event, datum) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }, [showTooltip]);

  return <>
           <svg width={parentWidth} height={parentHeight} ref={svgRef}>
             <rect width={parentWidth} height={parentHeight} fill="#efefef" />
             <rect width={width}
                   height={height}
                   x={xMin}
                   y={yMax}
                   fill="#fff"
             />
             <Group
               key="background"
             >
               {years.map((year, ix)=>
                 (<rect
                   width={colWidth}
                   height={height}
                   x={xScale(year) - colWidth/2}
                   y={yMax}
                   fill={ix%2==0 ?"#fff":"#fafafa"}
                  />)
               )}
             </Group>
             <GridRows
               lineStyle={{ pointerEvents: "none" }}
               scale={yScale}
               width={width}
               left={xMin}
               strokeDasharray="2,2"
               stroke="#eee"
             />
             <AxisBottom
               top={yMin}
               scale={xScale}
               data={years}
               tickFormat={(d) => d}
               tickLength={xTickLength}
               numTicks={years.length}
               label="Year"
               labelProps={{fontFamily:'Poppins-Medium', fontSize:'1rem', dy:'.7rem'}}
               tickLabelProps={{fontSize:'12px'}}
              />
              <YAxis
                xMin={xMin}
                yMin={3}
                yScale={yScale}
                label={prefs.display_unit}
                textAnchor="start"
                labelDx={-(xMin - 4)}
                numTicks={5}
                tickFormat={fmt}
              />
             { two_axis ?
               <RightYAxis
                xMin={xMax}
                yMin={3}
                yScale={rightYScale}
                label={prefs.right_display_unit}
                textAnchor="end"
                labelDx={30}
                numTicks={5}
                tickFormat={fmt}
                          />
               :"" }
             { !filteredData.length ?
               <text
                 x={(xMax-xMin)/2}
                 y={(yMin-yMax)/2}
               >
                 No Data
               </text> : '' }
             <Group
               key={`bars`}
             >
               <BarGroup
                 data={filteredData}
                 keys={barIndicators}
                 height={height}
                 x0={(d)=>d.year}
                 x0Scale={xScale}
                 x1Scale={indicatorScale}
                 yScale={yScale}
                 color={colorScaleBar}
               >
                 {(barGroups) =>
                   barGroups.map((barGroup) => (
                     <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                       {barGroup.bars.filter((bar)=> (bar.value !== undefined)).map((bar) => (
                         <rect
                           key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                           x={bar.x-(groupWidth)/2}
                           y={yScale(bar.value)}
                           width={bar.width}
                           height={yScale(0)-yScale(bar.value)}
                           fill={bar.color}
                           rx={4}
                           onMouseMove={(e)=>handleMouseMove(e, {year: filteredData[barGroup.index].year,
                                                                 indicator: bar.key,
                                                                 value:bar.value,
                                                                 unit: prefs.display_unit,
                                                                })}
                           onMouseLeave={handleMouseLeave}
                           onTouchMove={(e)=>handleMouseMove(e, {year: filteredData[barGroup.index].year,
                                                                 indicator: bar.key,
                                                                 value:bar.value,
                                                                 unit: prefs.display_unit,
                                                                })}
                           onTouchEnd={handleMouseLeave}
                         />
                       ))}
                     </Group>
                   ))
                 }
               </BarGroup>
             </Group>
             <Group
               key="lines"
             >
               {lineIndicators ? lineIndicators.map((ind, ix) => (
                 <>
                   <LinePath
                     key = {`line-${ix}`}
                     data={filteredData.filter((d)=>d[ind] !== undefined)}
                     stroke="#fff"
                     strokeWidth={8}
                     strokeOpacity={.5}
                     x={(d)=>xScale(d.year)}
                     y={(d)=>rightYScale(d[ind])}
                     onMouseMove={(e)=>handleMouseMove(e, {indicator: ind})}
                     onMouseLeave={handleMouseLeave}
                     onTouchMove={(e)=>handleMouseMove(e, {indicator: ind})}
                     onTouchEnd={handleMouseLeave}
                   />
                   <g>
                     {filteredData.filter((d)=>d[ind] !== undefined).map((d,iy)=> (
                       <circle
                         key={`circle-bg-${ix}-${iy}`}
                         fillOpacity={.5}
                         fill="#fff"
                         r={9}
                         cx={xScale(d.year)}
                         cy={rightYScale(d[ind])}
                         onMouseMove={(e)=>handleMouseMove(e, {year: d.year,
                                                               indicator: ind,
                                                               value:d[ind],
                                                               unit: right_display_unit,
                                                              })}
                         onMouseLeave={handleMouseLeave}
                         onTouchMove={(e)=>handleMouseMove(e, {year: d.year,
                                                               indicator: ind,
                                                               value:d[ind],
                                                               unit: right_display_unit,
                                                              })}
                         onTouchEnd={handleMouseLeave}
                       />
                     ))}
                   </g>
                   <LinePath
                     key = {`line-c-${ix}`}
                     data={filteredData.filter((d)=>d[ind] !== undefined)}
                     stroke={colorScaleLine(ind)}
                     strokeWidth={3}
                     x={(d)=>xScale(d.year)}
                     y={(d)=>rightYScale(d[ind])}
                     onMouseMove={(e)=>handleMouseMove(e, {indicator: ind})}
                     onMouseLeave={handleMouseLeave}
                     onTouchMove={(e)=>handleMouseMove(e, {indicator: ind})}
                     onTouchEnd={handleMouseLeave}
                   />
                   <g>
                   {filteredData.filter((d)=>d[ind] !== undefined).map((d,iy)=> (
                     <circle
                       key={`circle-${ix}-${iy}`}
                       stroke={colorScaleLine(ind)}
                       strokeWidth={2}
                       fillOpacity={1}
                       fill="#fff"
                       r={5}
                       cx={xScale(d.year)}
                       cy={rightYScale(d[ind])}
                       onMouseMove={(e)=>handleMouseMove(e, {year: d.year,
                                                             indicator: ind,
                                                             value:d[ind],
                                                             unit: right_display_unit,
                                                            })}
                       onMouseLeave={handleMouseLeave}
                       onTouchMove={(e)=>handleMouseMove(e, {year: d.year,
                                                             indicator: ind,
                                                             value:d[ind],
                                                             unit: right_display_unit,
                                                            })}
                       onTouchEnd={handleMouseLeave}
                     />
                   ))}
                   </g>
                 </>
               ))
                :'' }
             </Group>
           </svg>
           {tooltipOpen && tooltipData && tooltipLeft != null && tooltipTop != null && (
             <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
               <div>
                 <strong>{tooltipData.indicator}</strong><br />
                 {tooltipData.year ? (
                   <>
                     {fmtIndicator(tooltipData.value, tooltipData.indicator)} {tooltipData.unit}<br/>
                   ({tooltipData.year})
                   </>
                 ) :""}
               </div>
             </TooltipWithBounds>
           )}
         </>;
};

const DashboardChart = withParentSize(withTooltip(_DashboardChart));


const LineCircleLegendItem = ({fill, width, height, style}) => {
  const cleanWidth = typeof width === 'string' || typeof width === 'undefined' ? 0 : width;
  const cleanHeight = typeof height === 'string' || typeof height === 'undefined' ? 0 : height;
  const lineThickness=3;

  return (
    <svg width={width} height={height}>
      <Group top={cleanHeight / 2}>
        <line
          x1={0}
          x2={width}
          y1={0}
          y2={0}
          stroke={fill}
          strokeWidth={lineThickness}
          style={style}
        />
      </Group>
      <circle
        cx={cleanWidth/2}
        cy={cleanHeight/2}
        strokeWidth={2}
        r={3.5}
        fill='#fff'
        fillOpacity={1}
        stroke={fill}
      />
    </svg>
  );
};



const DashboardChartWithLegend = ({
  barIndicators=[],
  lineIndicators=[],
  data=[],
  ...props
}) => {
  if (!data.length) {
    return null;
  }
  const allIndicators = [...barIndicators, ...lineIndicators];

  const filteredData = data.map((e)=>
    Object.fromEntries(['year', ...allIndicators].map((k)=>
      [k, e[k]]).filter(([k,v])=> (v !== null && v!==undefined))))
        .filter(({year, ...rest})=> Object.values(rest).length)
        .sort((a,b)=>a.year-b.year);

  const colorScaleBar = scaleOrdinal({
    domain: barIndicators,
    range: schemeCategory10
  });

  const colorScaleLine = scaleOrdinal({
    domain: lineIndicators,
    range: schemeCategory10
  });


  const colorScale = (x) => colorScaleBar(x) || colorScaleLine(x);

  return (<div>
            <div className='dash-graph'>
              <DashboardChart
                barIndicators={barIndicators}
                lineIndicators={lineIndicators}
                filteredData={filteredData}
                colorScaleBar={colorScaleBar}
                colorScaleLine={colorScaleLine}
                {...props}
              />
            </div>
            <div
              className='dashboard-legend'
            >
              <LegendOrdinal
                scale={colorScaleBar}
                domain={barIndicators}
                key="bars"
                legendLabelProps={{className:'dashboard-legend-label'}}
                shapeStyle={()=>({borderRadius:'4px'})}
              />
              <LegendOrdinal
                scale={colorScaleLine}
                domain={lineIndicators}
                key="lines"
                legendLabelProps={{className:'dashboard-legend-label'}}
                shape={LineCircleLegendItem}
              />
            </div>
          </div>
         );

}


export { DashboardChart, DashboardChartWithLegend };
