'use strict';

const countries = {
  "ABW": "Aruba (Neth.)",
  "AFG": "Afghanistan",
  "AGO": "Angola",
  "AIA": "Anguilla (U.K.)",
  "ALB": "Albania",
  "AND": "Andorra",
  "ARE": "United Arab Emirates",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ASM": "American Samoa (U.S.)",
  "ATF": "French Southern and Antarctic Lands (Fr.)",
  "ATG": "Antigua and Barbuda",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BDI": "Burundi",
  "BEL": "Belgium",
  "BEN": "Benin",
  "BFA": "Burkina Faso",
  "BGD": "Bangladesh",
  "BGR": "Bulgaria",
  "BHR": "Bahrain",
  "BHS": "The Bahamas",
  "BIH": "Bosnia and Herzegovina",
  "BLR": "Belarus",
  "BLZ": "Belize",
  "BMU": "Bermuda (U.K.)",
  "BOL": "Bolivia",
  "BRA": "Brazil",
  "BRB": "Barbados",
  "BRN": "Brunei Darussalam",
  "BTN": "Bhutan",
  "BVT": "Bouvet Island (Nor.)",
  "BWA": "Botswana",
  "CAF": "Central African Republic",
  "CAN": "Canada",
  "CCK": "Cocos (Keeling) Islands (Aus.)",
  "CHE": "Switzerland",
  "CHL": "Chile",
  "CHN": "China",
  "CIV": "Côte d’Ivoire",
  "CMR": "Cameroon",
  "COD": "Congo, Dem. Rep.",
  "COG": "Congo",
  "COK": "Cook Islands (N.Z.)",
  "COL": "Colombia",
  "COM": "Comoros",
  "CPV": "Cabo Verde",
  "CRI": "Costa Rica",
  "CUB": "Cuba",
  "CUW": "Curaçao (Neth.)",
  "CXR": "Christmas Island (Aus.)",
  "CYM": "Cayman Islands (U.K.)",
  "CYP": "Cyprus",
  "CZE": "Czech Republic",
  "DEU": "Germany",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DNK": "Denmark",
  "DOM": "Dominican Republic",
  "DZA": "Algeria",
  "ECU": "Ecuador",
  "EGY": "Arab Republic of Egypt",
  "ERI": "Eritrea",
  "ESP": "Spain",
  "EST": "Estonia",
  "ETH": "Ethiopia",
  "FIN": "Finland",
  "FJI": "Fiji",
  "FRA": "France",
  "FRO": "Faroe Islands (Den.)",
  "FSM": "Micronesia, Fed. Sts.",
  "GAB": "Gabon",
  "GBR": "United Kingdom",
  "GEO": "Georgia",
  "GGY": "Guernsey (U.K.)",
  "GHA": "Ghana",
  "GIB": "Gibraltar (U.K.)",
  "GIN": "Guinea",
  "GMB": "Gambia, The",
  "GNB": "Guinea-Bissau",
  "GNQ": "Equatorial Guinea",
  "GRC": "Greece",
  "GRD": "Grenada",
  "GRL": "Greenland (Den.)",
  "GTM": "Guatemala",
  "GUM": "Guam (U.S.)",
  "GUY": "Guyana",
  "HKG": "Hong Kong, SAR",
  "HMD": "Heard Island and McDonald Islands (Aus.)",
  "HND": "Honduras",
  "HRV": "Croatia",
  "HTI": "Haiti",
  "HUN": "Hungary",
  "IDN": "Indonesia",
  "IMN": "Isle of Man (U.K.)",
  "IND": "India",
  "IOT": "British Indian Ocean Territory (U.K.)",
  "IRL": "Ireland",
  "IRN": "Islamic Republic of Iran",
  "IRQ": "Iraq",
  "ISL": "Iceland",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JEY": "Jersey (U.K.)",
  "JOR": "Jordan",
  "JPN": "Japan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KGZ": "Kyrgyz Republic",
  "KHM": "Cambodia",
  "KIR": "Kiribati",
  "KNA": "Saint Kitts and Nevis",
  "KOR": "Republic of Korea",
  "KSV": "Kosovo",
  "KWT": "Kuwait",
  "LAO": "Lao People's Democratic Republic",
  "LBN": "Lebanon",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LCA": "St. Lucia",
  "LIE": "Liechtenstein",
  "LKA": "Sri Lanka",
  "LSO": "Lesotho",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "LVA": "Latvia",
  "MAC": "Macau, SAR",
  "MAF": "Saint-Martin (Fr.)",
  "MAR": "Morocco",
  "MCO": "Monaco",
  "MDA": "Moldova",
  "MDG": "Madagascar",
  "MDV": "Maldives",
  "MEX": "Mexico",
  "MHL": "Marshall Islands",
  "MKD": "FYR of Macedonia",
  "MLI": "Mali",
  "MLT": "Malta",
  "MMR": "Myanmar",
  "MNE": "Montenegro",
  "MNG": "Mongolia",
  "MNP": "Northern Mariana Islands (U.S.)",
  "MOZ": "Mozambique",
  "MRT": "Mauritania",
  "MSR": "Montserrat (U.K.)",
  "MUS": "Mauritius",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "NAM": "Namibia",
  "NCL": "New Caledonia (Fr.)",
  "NER": "Niger",
  "NFK": "Norfolk Island (Aus.)",
  "NGA": "Nigeria",
  "NIC": "Nicaragua",
  "NIU": "Niue (N.Z.)",
  "NLD": "Netherlands",
  "NOR": "Norway",
  "NPL": "Nepal",
  "NRU": "Nauru",
  "NZL": "New Zealand",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PAN": "Panama",
  "PCN": "Pitcairn Islands (U.K.)",
  "PER": "Peru",
  "PHL": "Philippines",
  "PLW": "Palau",
  "PNG": "Papua New Guinea",
  "POL": "Poland",
  "PRI": "Puerto Rico (U.S.)",
  "PRK": "D. P. R. of Korea",
  "PRT": "Portugal",
  "PRY": "Paraguay",
  "PSE": "West Bank and Gaza",
  "PYF": "French Polynesia (Fr.)",
  "QAT": "Qatar",
  "ROU": "Romania",
  "RUS": "Russian Federation",
  "RWA": "Rwanda",
  "SAU": "Saudi Arabia",
  "SDN": "Sudan",
  "SEN": "Senegal",
  "SGP": "Singapore",
  "SGS": "South Georgia and the South Sandwich Islands (U.K.)",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha (U.K.)",
  "SJM": "Svalbard and Jan Mayen (Nor.)",
  "SLB": "Solomon Islands",
  "SLE": "Sierra Leone",
  "SLV": "El Salvador",
  "SMR": "San Marino",
  "SOM": "Somalia",
  "SPM": "Saint-Pierre-et-Miquelon (Fr.)",
  "SRB": "Serbia",
  "SSD": "South Sudan",
  "STP": "São Tomé and Príncipe",
  "SUR": "Suriname",
  "SVK": "Slovak Republic",
  "SVN": "Slovenia",
  "SWE": "Sweden",
  "SWZ": "Eswatini",
  "SXM": "Sint Maarten (Neth.)",
  "SYC": "Seychelles",
  "SYR": "Syrian Arab Republic",
  "TCA": "Turks and Caicos Islands (U.K.)",
  "TCD": "Chad",
  "TGO": "Togo",
  "THA": "Thailand",
  "TJK": "Tajikistan",
  "TKL": "Tokelau (N.Z.)",
  "TKM": "Turkmenistan",
  "TLS": "Timor-Leste",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Türkiye",
  "TUV": "Tuvalu",
  "TWN": "Taiwan",
  "TZA": "Tanzania",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "URY": "Uruguay",
  "USA": "United States of America",
  "UZB": "Uzbekistan",
  "VAT": "Vatican City",
  "VCT": "Saint Vincent and the Grenadines",
  "VEN": "R. B. de Venezuela",
  "VGB": "British Virgin Islands (U.K.)",
  "VIR": "United States Virgin Islands (U.S.)",
  "VNM": "Vietnam",
  "VUT": "Vanuatu",
  "WLF": "Wallis and Futuna (Fr.)",
  "WSM": "Samoa",
  "YEM": "Republic of Yemen",
  "ZAF": "South Africa",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe"
};


const iso2Map = {
    "AD": "AND",
    "AE": "ARE",
    "AF": "AFG",
    "AG": "ATG",
    "AI": "AIA",
    "AL": "ALB",
    "AM": "ARM",
    "AO": "AGO",
    "AQ": "ATA",
    "AR": "ARG",
    "AS": "ASM",
    "AT": "AUT",
    "AU": "AUS",
    "AW": "ABW",
    "AX": "ALA",
    "AZ": "AZE",
    "BA": "BIH",
    "BB": "BRB",
    "BD": "BGD",
    "BE": "BEL",
    "BF": "BFA",
    "BG": "BGR",
    "BH": "BHR",
    "BI": "BDI",
    "BJ": "BEN",
    "BL": "BLM",
    "BM": "BMU",
    "BN": "BRN",
    "BO": "BOL",
    "BQ": "BES",
    "BR": "BRA",
    "BS": "BHS",
    "BT": "BTN",
    "BV": "BVT",
    "BW": "BWA",
    "BY": "BLR",
    "BZ": "BLZ",
    "CA": "CAN",
    "CC": "CCK",
    "CD": "COD",
    "CF": "CAF",
    "CG": "COG",
    "CH": "CHE",
    "CI": "CIV",
    "CK": "COK",
    "CL": "CHL",
    "CM": "CMR",
    "CN": "CHN",
    "CO": "COL",
    "CR": "CRI",
    "CU": "CUB",
    "CV": "CPV",
    "CW": "CUW",
    "CX": "CXR",
    "CY": "CYP",
    "CZ": "CZE",
    "DE": "DEU",
    "DJ": "DJI",
    "DK": "DNK",
    "DM": "DMA",
    "DO": "DOM",
    "DZ": "DZA",
    "EC": "ECU",
    "EE": "EST",
    "EG": "EGY",
    "EH": "ESH",
    "ER": "ERI",
    "ES": "ESP",
    "ET": "ETH",
    "FI": "FIN",
    "FJ": "FJI",
    "FK": "FLK",
    "FM": "FSM",
    "FO": "FRO",
    "FR": "FRA",
    "GA": "GAB",
    "GB": "GBR",
    "GD": "GRD",
    "GE": "GEO",
    "GF": "GUF",
    "GG": "GGY",
    "GH": "GHA",
    "GI": "GIB",
    "GL": "GRL",
    "GM": "GMB",
    "GN": "GIN",
    "GP": "GLP",
    "GQ": "GNQ",
    "GR": "GRC",
    "GS": "SGS",
    "GT": "GTM",
    "GU": "GUM",
    "GW": "GNB",
    "GY": "GUY",
    "HK": "HKG",
    "HM": "HMD",
    "HN": "HND",
    "HR": "HRV",
    "HT": "HTI",
    "HU": "HUN",
    "ID": "IDN",
    "IE": "IRL",
    "IL": "ISR",
    "IM": "IMN",
    "IN": "IND",
    "IO": "IOT",
    "IQ": "IRQ",
    "IR": "IRN",
    "IS": "ISL",
    "IT": "ITA",
    "JE": "JEY",
    "JM": "JAM",
    "JO": "JOR",
    "JP": "JPN",
    "KE": "KEN",
    "KG": "KGZ",
    "KH": "KHM",
    "KI": "KIR",
    "KM": "COM",
    "KN": "KNA",
    "KP": "PRK",
    "KR": "KOR",
    "KSV":"KSV",
    "KW": "KWT",
    "KY": "CYM",
    "KZ": "KAZ",
    "LA": "LAO",
    "LB": "LBN",
    "LC": "LCA",
    "LI": "LIE",
    "LK": "LKA",
    "LR": "LBR",
    "LS": "LSO",
    "LT": "LTU",
    "LU": "LUX",
    "LV": "LVA",
    "LY": "LBY",
    "MA": "MAR",
    "MC": "MCO",
    "MD": "MDA",
    "ME": "MNE",
    "MF": "MAF",
    "MG": "MDG",
    "MH": "MHL",
    "MK": "MKD",
    "ML": "MLI",
    "MM": "MMR",
    "MN": "MNG",
    "MO": "MAC",
    "MP": "MNP",
    "MQ": "MTQ",
    "MR": "MRT",
    "MS": "MSR",
    "MT": "MLT",
    "MU": "MUS",
    "MV": "MDV",
    "MW": "MWI",
    "MX": "MEX",
    "MY": "MYS",
    "MZ": "MOZ",
    "NA": "NAM",
    "NC": "NCL",
    "NE": "NER",
    "NF": "NFK",
    "NG": "NGA",
    "NI": "NIC",
    "NL": "NLD",
    "NO": "NOR",
    "NP": "NPL",
    "NR": "NRU",
    "NU": "NIU",
    "NZ": "NZL",
    "OM": "OMN",
    "PA": "PAN",
    "PE": "PER",
    "PF": "PYF",
    "PG": "PNG",
    "PH": "PHL",
    "PK": "PAK",
    "PL": "POL",
    "PM": "SPM",
    "PN": "PCN",
    "PR": "PRI",
    "PS": "PSE",
    "PT": "PRT",
    "PW": "PLW",
    "PY": "PRY",
    "QA": "QAT",
    "RE": "REU",
    "RO": "ROU",
    "RS": "SRB",
    "RU": "RUS",
    "RW": "RWA",
    "SA": "SAU",
    "SB": "SLB",
    "SC": "SYC",
    "SD": "SDN",
    "SE": "SWE",
    "SG": "SGP",
    "SH": "SHN",
    "SI": "SVN",
    "SJ": "SJM",
    "SK": "SVK",
    "SL": "SLE",
    "SM": "SMR",
    "SN": "SEN",
    "SO": "SOM",
    "SR": "SUR",
    "SS": "SSD",
    "ST": "STP",
    "SV": "SLV",
    "SX": "SXM",
    "SY": "SYR",
    "SZ": "SWZ",
    "TC": "TCA",
    "TD": "TCD",
    "TF": "ATF",
    "TG": "TGO",
    "TH": "THA",
    "TJ": "TJK",
    "TK": "TKL",
    "TL": "TLS",
    "TM": "TKM",
    "TN": "TUN",
    "TO": "TON",
    "TR": "TUR",
    "TT": "TTO",
    "TV": "TUV",
    "TW": "TWN",
    "TZ": "TZA",
    "UA": "UKR",
    "UG": "UGA",
    "UM": "UMI",
    "US": "USA",
    "UY": "URY",
    "UZ": "UZB",
    "VA": "VAT",
    "VC": "VCT",
    "VE": "VEN",
    "VG": "VGB",
    "VI": "VIR",
    "VN": "VNM",
    "VU": "VUT",
    "WF": "WLF",
    "WS": "WSM",
    "XK": "XKX",
    "YE": "YEM",
    "YT": "MYT",
    "ZA": "ZAF",
    "ZM": "ZMB",
    "ZW": "ZWE"
};


const countryForIso = (x) => { return countries[x]; };

const iso2s = Object.keys(iso2Map);
const iso322 = {};
iso2s.forEach(k => {iso322[iso2Map[k]] = k;
                   });

const _countryToIso = Object.fromEntries(
  Object.keys(countries).map((iso3)=>[countries[iso3], iso3]));

const iso3ForCountry = (x) => _countryToIso[x];
const iso2ForCountry = (x) => iso322[_countryToIso(x)];

export {countries, countryForIso, iso2Map, iso322, iso3ForCountry, iso2ForCountry};
