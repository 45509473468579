/* global Intl */
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import {useFetch} from './hooks';

import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { GridRows } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import YAxis from "./YAxis";
import { withParentSize } from '@visx/responsive';
import { withTooltip, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import { schemeCategory10 } from 'd3-scale-chromatic';


import { AccurateBeeswarm } from 'accurate-beeswarm-plot';


const radius = 3;


const _DetailChart = ({
  parentWidth,
  parentHeight,
  hideTooltip,
  showTooltip,
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  direction=0,
  indicatorName='',
  data=[],
  medianVal,
  highlightThreshold,
  medianStyle='',
  limitMax,
  limitMin,
  colorScale
}) => {


  const yearMax =  2020; //Math.max(...data.map(e => e.year));
  const yearMin =  2020; // Math.min(...data.map(e => e.year));
  const parsedData = data.map(e => ({year: 2020, value:e}));

  let dataMax =  Math.max(...data);
  let dataMin =  Math.min(...data);

  if (limitMax !== undefined) {
    dataMax = Math.min(limitMax, dataMax);
  }
  if (limitMin !== undefined) {
    dataMin = Math.max(limitMin, dataMin);
  }

  const yScale = scaleLinear( {
    domain: [Math.floor(dataMin), Math.ceil(dataMax)]
  });

  const xScale = scaleLinear( {
    domain: [yearMin - 0.5, yearMax+ 0.5],
  });



  const xMin = 5;
  const yMax = 5;

  const xMax = parentWidth-5;
  const yMin = parentHeight-5;

  const width = xMax-xMin;
  const height = yMin-yMax;

  xScale.range([xMin, xMax]);
  yScale.range([yMin, yMax]);

  const colWidth = xScale(yearMax) - xScale(yearMax-1);
  const boxWidth = colWidth*.125;

  const dataTransform = (d) => {
    if (!d) { return []; }
    var transformedData = [];


    const dodged = new AccurateBeeswarm(d, radius+2, d => yScale(d.value))
          .withTiesBrokenRandomly()
          .calculateYPositions();

    const maxDy = Math.max(...dodged.map(({datum, x, y})=>Math.abs(y)));
    let scaleFactor = 1;
    if (maxDy > colWidth/2) {
      scaleFactor = 0.95*(colWidth/2)/maxDy;
    }

    const transformed = dodged.map(({datum, x, y})=>({year: datum.year,
                                                      value: datum.value,
                                                      y: x,
                                                      x: xScale(datum.year) + y * scaleFactor,
                                                     }));
    return transformed;
};

  const series = dataTransform(parsedData);

  if (!data){
    return null;
  }



  const fill = (v) =>
        colorScale ? colorScale(v) : (isHighlight(v) ? '#00a' : '#999');

  const isHighlight = (d) =>
        highlightThreshold !== undefined && d.value > highlightThreshold;

  return <div>
           <svg width={parentWidth} height={parentHeight}>
             <rect width={parentWidth} height={parentHeight} fill="#efefef" />
             <rect width={width}
                   height={height}
                   x={xMin}
                   y={yMax}
                   fill="#fff"
             />
             <GridRows
               lineStyle={{ pointerEvents: "none" }}
               scale={yScale}
               width={width}
               left={xMin}
               strokeDasharray="2,2"
               stroke="#eee"
             />
             <Group
               key={`fg-circles`}
             >
               {series.map((d,i) => (
                 <circle
                   key={`marker-${i}`}
                   r={ radius }
                   cx={d.x}
                   cy={d.y}
                   fill={ fill(d) }
                   opacity = {.8}
                 />
               ))}
               { medianStyle=='line' && medianVal !== undefined ? (
                 <Group>
                   <line
                     key={`median-shadow`}
                     x1 = {xMin + 5}
                     x2 = {xMax - 5}
                     y1 = {yScale(medianVal)}
                     y2 = {yScale(medianVal)}
                     stroke="#eee"
                     strokeWidth={4}
                     opacity={0.7}
                   />
                   <line
                     key={`median`}
                     x1 = {xMin + 5}
                     x2 = {xMax - 5}
                     y1 = {yScale(medianVal)}
                     y2 = {yScale(medianVal)}
                     stroke="#002f54"
                     strokeWidth={2}
                     strokeDasharray="2,2"
                   />
                 </Group>
                 )
                 : '' }

             </Group>
           </svg>
          </div>;
};

const DetailChart = withParentSize(withTooltip(_DetailChart));

const colorScale = (v) => {
  if (v.value < 60) { return "green"; }
  if (v.value < 150) { return "orange"; }
  return "red";
};

const DaysDetailChart = (props) => (<DetailChart
                                      colorScale={colorScale}
                                      {...props}
                                    />);

// Returns a color function of the threshold
const revColorScale = (threshold) =>
      (v) =>  v.value < threshold ?  '#00a' : '#999';

const RevDetailChart = ({highlightThreshold, ...props}) =>
      (<DetailChart
         colorScale={revColorScale(highlightThreshold)}
         {...props}
       />);

export { DetailChart, RevDetailChart };
