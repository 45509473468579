/* global Intl */
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import {useFetch} from './hooks';

import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { GridRows } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { YAxis } from "./YAxis";
import { withParentSize, ParentSize } from '@visx/responsive';
import { withTooltip, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { voronoi, VoronoiPolygon } from '@visx/voronoi';
import { BoxPlot } from '@visx/stats';
import { Line, LinePath }  from '@visx/shape';

import {fmtCountry, fmtRegion, fmtIndicator} from "../components/format";

import { schemeCategory10 } from 'd3-scale-chromatic';

const firstQuartile = d=>d.medians.get(1);
const median = d=>d.medians.get(2);
const thirdQuartile = d=>d.medians.get(3);
const min = d=>d.medians.get(0);
const max = d=>d.medians.get(4);


import { AccurateBeeswarm } from 'accurate-beeswarm-plot';

let tooltipTimeout;
const radius = 3;

const _formatter = Intl.NumberFormat('en-US', {maximumSignificantDigits: 3});
const fmt = x => _formatter.format(x);

//const margin = {
//  top


const _PerformanceChart = ({
  parentWidth,
  parentHeight,
  hideTooltip,
  showTooltip,
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  indicatorName='',
  data=[],
  medians=[],
  selectedUtility='',
  medianStyle='',
  prefs,
}) => {

  const svgRef = useRef(null);

  const yearMax =  Math.max(...data.map(e => e.year));
  const yearMin =  Math.min(...data.map(e => e.year));

  const dataMax =  Math.max(...data.map(e => e.value));
  const dataMin =  Math.min(...data.map(e => e.value));

  const displayDataMax = Math.ceil(Math.min(dataMax, prefs.limit_max));
  let displayDataMin = Math.floor(Math.max(dataMin, prefs.limit_min));

  // if min is positive and less than 10% of max, go to 0. i.e., don't just miss 0
  displayDataMin = displayDataMin > 0 && displayDataMax/displayDataMin > 10 ? 0 : displayDataMin;

  const utility_types = [...(new Set([...data.map(e=>e.utility_type)]))];
  const years = [...(new Set([...data.map(e=>e.year)]))].toSorted();



  const yScale = scaleLinear( {
    domain: [displayDataMin,
             displayDataMax]
  });

  const xScale = scaleLinear( {
    domain: [yearMin - 0.5, yearMax+ 0.5],
  });

  const colorScale = scaleOrdinal({
    domain: utility_types,
    range: schemeCategory10
  });


  const xMin = 40;
  const xMax = parentWidth-10;
  const yMin = parentHeight-37;
  const yMax = 25;
  const xTickLength = 3;
  const width = xMax-xMin;
  const height = yMin-yMax;

  xScale.range([xMin, xMax]);
  yScale.range([yMin, yMax]);

  const colWidth = xScale(yearMax) - xScale(yearMax-1);
  const boxWidth = colWidth*.125;
  const boxOffset = -boxWidth*2;

  const dataTransform = (d) => {
    if (!d) { return []; }
    var transformedData = [];
    for (let year=yearMin; year <=yearMax; year++){
      // this is going to put the y value in x, and the offset x in y.


      const dodged = new AccurateBeeswarm(data.filter(d=>(d.year==year &&
                                                          d.value >= displayDataMin &&
                                                          d.value <= displayDataMax)), radius-1, d => yScale(d.value))
            .withTiesBrokenRandomly()
            .calculateYPositions();

      // make sure that we're narrower than a column
      const maxDy = Math.max(...dodged.map(({datum, x, y})=>Math.abs(y)));
      let scaleFactor = 1;
      if (maxDy > colWidth/2) {
        scaleFactor = 0.95*(colWidth/2)/maxDy;
      }

      const transformed = dodged.map(({datum, x, y})=>({year: datum.year,
                                                        value: datum.value,
                                                        y: x,
                                                        x: xScale(datum.year) + y * scaleFactor,
                                                        name: datum.utility_short_display,
                                                        utility: datum.utility,
                                                        utility_type: datum.utility_type,
                                                        country: datum.country,
                                                       }));

      transformedData = transformedData.concat(transformed);
    }
    return transformedData;
  };

  const series = useMemo(
    ()=> dataTransform(data), [data, xScale, yScale]);

  const selected = series.filter(d => d.utility == selectedUtility);

  // const median_series = medians.map(({year,value, utility_type})=> ({year,
  //                                                                    value,
  //                                                                    name:utility_type,
  //                                                                    y: yScale(value),
  //                                                                    x: xScale(year)
  //                                                                   }));

  const voronoiLayout = useMemo(
    () =>
    voronoi({
      x: (d) => d.x,
      y: (d) => d.y,
      width: parentWidth,
      height: parentHeight,
    })(series),
    [parentWidth, parentHeight, data],
  );

  const handleMouseMove = useCallback((event) => {
      if (tooltipTimeout) clearTimeout(tooltipTimeout);
      if (!svgRef.current) return;

      // find the nearest polygon to the current mouse position
      const point = localPoint(svgRef.current, event);
      if (!point) return;
      const neighborRadius = 100;
      const closest = voronoiLayout.find(point.x, point.y, neighborRadius);
      if (closest) {
        showTooltip({
          tooltipLeft: closest.data.x,
          tooltipTop: closest.data.y,
          tooltipData: closest.data,
        });
      }
    },
    [xScale, yScale, showTooltip, voronoiLayout],
  );

  const boxplotMouseMove = useCallback((event, data) => {
    const top = median(data);
    showTooltip({
      tooltipLeft: xScale(data.year) - boxWidth/2,
      tooltipTop: yScale(median(data)),
      tooltipData: data,
    });
  }, [showTooltip, xScale]);


  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  if (!data){
    return null;
  }

  const isTT = (d) =>
        tooltipData && tooltipData.utility == d.utility;

  const highlighted = series.filter(d => isTT(d));

  return <>
           <svg width={parentWidth} height={parentHeight} ref={svgRef}>
             <rect width={parentWidth} height={parentHeight} fill="#e6ecf499" />
             <rect width={width}
                   height={height}
                   x={xMin}
                   y={yMax}
                   fill="#fff"
             />
             <Group
               key="background"
             >
               {years.map((year, ix)=>
                 (<rect
                   width={colWidth}
                   height={height}
                   x={xScale(year) - colWidth/2}
                   y={yMax}
                   fill={ix%2==0 ?"#fff":"#fafafa"}
                  />)
               )}
             </Group>
             <GridRows
               lineStyle={{ pointerEvents: "none" }}
               scale={yScale}
               width={width}
               left={xMin}
               strokeDasharray="2,2"
               stroke="#eee"
             />
             {displayDataMin < 0 ?
              <Line
                from={{x:xMin, y:yScale(0)}}
                to={{x:xMax, y:yScale(0)}}
                stroke='#999'
                strokeWidth={1}
                strokeDasharray="2,2"

              /> : ''}
             <AxisBottom
               top={yMin}
               scale={xScale}
               data={years}
               tickFormat={(d) => d}
               tickLength={xTickLength}
               numTicks={years.length}
               label="Year"
               labelClassName='chart__xaxis_label'
               labelProps={{fontFamily:'Poppins-Medium', fontSize:'1rem', dy:'.7rem'}}
               tickLabelProps={{fontSize:'12px'}}
               /* hideAxisLine={ displayDataMin < 0 } */
              />
              <YAxis
                xMin={xMin}
                yMin={3}
                yScale={yScale}
                label={prefs.display_unit}
                textAnchor="start"
                labelDx={-(xMin - 4)}
                numTicks={5}
                tickFormat={fmt}
              />

             <Group
               key="fg-circles"
             >
               {series.map((d,i) => (
                 <circle
                   key={`marker-${i}`}
                   r={ radius}
                   cx={d.x}
                   cy={d.y}
                   stroke='fff'
                   fill= {selectedUtility ? "#999": '#002f5499'}
                 />
               ))}
             </Group>
             <Group
               key="selected"
             >
               <LinePath
                 key='line-shadow'
                 data={selected}
                 stroke="#ffffff99"
                 strokeWidth={3}
                 x={d=>d.x}
                 y={d=>d.y}
               />
               <LinePath
                 key='line'
                 data={selected}
                 stroke="#002f54"
                 strokeWidth={1.5}
                 x={d=>d.x}
                 y={d=>d.y}
               />

               {/* Selected Utility */}
               {selected.map((d,i) => (
                 <>
                 <circle
                   key={`selected-${i}`}
                   r={radius*2}
                   cx={d.x}
                   cy={d.y}
                   stroke='#000'
                   fill='#3d689e' /*'#ffe800'*/
                 />
                   <rect
                     width={36}
                     height={12}
                     fill="#ffffff99"
                     x={d.x + 2.5*radius}
                     y={d.y - 6}
                     rx={3}
                     ry={3}
                   />
                   <Text
                     key={`label-${i}`}
                     x={d.x}
                     y={d.y}
                     dx={3*radius}
                     textAnchor="start"
                     verticalAnchor="middle"
                     fontSize={12}
                     fontFamily="Arial"
                   >{fmtIndicator(d.value, indicatorName)}
                   </Text>
                 </>
               ))}
             </Group>
             <Group key="hovered"
             >
               {/* Hovered Utility */}
               {highlighted.map((d,i) => (
                 <circle
                   key={`highlight-marker-${i}`}
                   r={ radius*2}
                   cx={d.x}
                   cy={d.y}
                   stroke='#000'
                   fill='#A00'
                 />
               ))}


             </Group>
             <Group
               key='voronoi'
             >
               {/* enable to show the voronoi layout used for the event capture detection */}
               {false && voronoiLayout.polygons().map((polygon, i) => (
                  <VoronoiPolygon
                    key={`polygon-${i}`}
                    polygon={polygon}
                    fill="#333"
                    stroke="#333"
                    strokeWidth={1}
                    strokeOpacity={0.2}
                    fillOpacity={tooltipData === polygon.data ? 0.5 : 0}
                  />
                ))}
             </Group>

             {/* capture events here, in one place */}
             <rect width={width}
                   height={height}
                   x={xMin}
                   y={yMax}
                   fill="transparent"
                   onMouseMove={handleMouseMove}
                   onMouseLeave={handleMouseLeave}
                   onTouchMove={handleMouseMove}
                   onTouchEnd={handleMouseLeave}
             />
             { medianStyle=='box' ? (
               <Group
                 key={`fg-box`}
               >
                   {medians.map((d,i) => (
                     <BoxPlot
                       key={`box-${i}`}
                       min={Math.max(min(d), displayDataMin) }
                       max={Math.min(max(d), displayDataMax) }
                       firstQuartile={Math.max(firstQuartile(d), displayDataMin) }
                       thirdQuartile={Math.min(thirdQuartile(d), displayDataMax) }
                       median={median(d)}
                       left={xScale(d.year) - boxWidth/2 + boxOffset}
                       boxWidth={boxWidth}
                       fill="#ffe800"
                       fillOpacity={0.50}
                       stroke="#333"
                       strokeWidth={1}
                       valueScale={yScale}
                       container={true}
                       containerProps={{
                         onMouseMove:(e)=>boxplotMouseMove(e,d),
                         onMouseLeave:handleMouseLeave,
                         onTouchMove:(e)=>boxplotMouseMove(e,d),
                         onTouchEnd:handleMouseLeave,
                       }}
                     />
                   ))}

               </Group>)
               : '' }
           </svg>
           {tooltipOpen && tooltipData && tooltipLeft != null && tooltipTop != null && (
             <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
             {tooltipData.country ? (
               <div>
                 <strong>{fmtCountry(tooltipData.country)} -- {tooltipData.name}</strong> ({tooltipData.year})<br/>
                 {indicatorName}: {fmtIndicator(tooltipData.value, indicatorName)} {prefs.display_unit} < br/>
               </div>
             ) : (
               <div>
                 <strong>{indicatorName} distribution</strong> ({tooltipData.year})<br/>
                 <table className="t_tooltip">
                   <tr><td>Max Value</td><td> {fmtIndicator(tooltipData.max, indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>90th percentile</td><td> {fmtIndicator(max(tooltipData), indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>Third Quartile</td><td> {fmtIndicator(thirdQuartile(tooltipData), indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>Median</td><td> {fmtIndicator(median(tooltipData), indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>First Quartile</td><td> {fmtIndicator(firstQuartile(tooltipData), indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>10th percentile</td><td> {fmtIndicator(min(tooltipData), indicatorName)} {prefs.display_unit} </td></tr>
                   <tr><td>Minimim Value</td><td>{fmtIndicator(tooltipData.min, indicatorName)} {prefs.display_unit} </td></tr>
                 </table>
               </div>
             )}
             </TooltipWithBounds>
           )}
  </>;
};


const _SizedPerformanceChart = (props) => (
  <ParentSize
    className='perf-graph-parent'>
    {(parent) => (
      <_PerformanceChart
        parentWidth={parent.width}
        parentHeight={parent.height}
        {...props}
      />
    )}
  </ParentSize>
);

const TtPerformanceChart = withTooltip(_SizedPerformanceChart);


const PerformanceChart = ({
  data=[],
  prefs,
  ...props
}) => {


  const ctExcluded = data.filter((d)=>d.value > prefs.limit_max || d.value < prefs.limit_min).length;

  return  <>
            <div className='perf-graph'>
              <TtPerformanceChart
                data={data}
                prefs={prefs}
                {...props}
              />
            </div>
            <div className='performance_excluded_text'>
              {(ctExcluded > 0) ? `${ctExcluded} outlying data points are not shown. See the Grid View for all values.` :''}
            </div>
          </>;
};

export { PerformanceChart };
