import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { CountryFlags } from "../components/CountryFlags";

import NotFound from "./NotFound";

import { iso3ForCountry } from "../static/data/iso3";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import {fmtCountry, fmtRegion, fmtGrid, fmtUtilType} from "../components/format";
import {DataContext} from "../components/DataContext";
import { Grid } from '../components/Grid';



export default function UtilitiesGrid({utility, grid}) {
  const dataLayer = useContext(DataContext);
  const [data, setData] = useState([]);
  const [db_init, setDbInit] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [utilityInfo, setUtilityInfo] = useState({});
  const [utilities, setUtilities] = useState([]);

  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!db_init) {
      if (dataLayer.isDbInit()) {
        console.log('already inited');
        setDbInit(true);
        dataLayer.getColumns(setColumns);
      } else {
        console.log('calling into datalayer.init_db');
        dataLayer.init_db(setDbInit, setColumns);
        return;
      }
    }

    const _d = async () => {
      console.log('getting basic info');
      const sql = 'select country, utility_display, utility_short_display, utility_type, ownership from data where utility=? limit 1';
      const _data = await dataLayer.query(sql, [utility]);
      console.log(_data[0]);
      if (!_data) {
        setNotFound(true);
      }
      setUtilityInfo(_data[0]);
    };


    const _grid = async () => {
      if (!columns.length) {
        await dataLayer.getColumns(setColumns);
      }
      const _data = await dataLayer.getUtilityGridData(utility);
      setData(_data);
    };

    const _util = async () => {
      const _util_data = await dataLayer.query(`
          select distinct country, utility, utility_display
           from data
           order by country, utility`, []);
      if (_util_data){
        setUtilities(_util_data);
      }
    };


    _d().then(_grid).then(_util);

  }, [utility, grid, db_init, columns]);


  if (notFound) {
    return (<NotFound/>);
  }

  if (!data) {
    return "";
  }

  const handleChange = (event) => {
    const utility = event.target.value;
    if (utility !== "") {
      navigate(`/utilities/${utility}/grid`);
    }
  };

  const options = utilities.map(e => (
        <option key={e.utility} value={e.utility}>
          {e.country} - {e.utility_display}
        </option>
  ));

  return (
    <Layout customClassName="utility-content-section">
      <aside className="mt-4 mb-5">
          <div className="d-flex justify-content-between">
          <div>
          <NavLink
            to={"/"}
            className="text-decoration-none fw-light text-black ps-1 pe-1"
          >
            Home
          </NavLink>
          /
          <NavLink
            to={"/utilities/"}
            className="text-decoration-none fw-light text-black ps-1 pe-1"
          >
            Utilities
          </NavLink>
         / {utilityInfo.utility_short_display}
         </div>

         <div className="w-50">
      <select onChange={handleChange} className="form-select">
        <option value="">-- Select Utility --</option>
        {options}
      </select>
    </div>
    </div>
        </aside>

      <div className="row">
        <div className="d-flex">
          <div>
            <CountryFlags
              iso3={iso3ForCountry(utilityInfo.country)}
              style={{
                marginRight: "2rem",
                width: "7rem",
                border: "1px solid #aaa",
              }}
            />
          </div>
          <div>
            <h1 className="fw-light">{fmtCountry(utilityInfo.country)}</h1>
            <h5 className="text-navy-blue fw-bold extra-text-spacing">{utilityInfo.utility_display} ({utilityInfo.utility_short_display})</h5>
            <div className="d-flex gap-4 mt-3">
              <h6 className="text-navy-blue extra-text-spacing">
                <span className="fw-bold">Utility type:</span> {fmtUtilType(utilityInfo.utility_type)}</h6>
              <h6 className="text-navy-blue extra-text-spacing">
                <span className="fw-bold">Ownership:</span> {utilityInfo.ownership}</h6>
            </div>
          </div>
        </div>
      </div>


      <section id="grid">
        <div className="d-flex justify-content-end mb-3 gap-3">
        <NavLink
          to={`/utilities/${utility}`}
          className="btn btn-yellow"
        >
          <>
          Dashboard View
          </>
        </NavLink>
          <a
            className="btn btn-navy-blue"
            title="Download as CSV"
            onClick={e=>dataLayer.csvDownloadForUtility(utility)}
          >
            Download CSV <FontAwesomeIcon icon={faGrip} className="ms-2" />
          </a>
        </div>
        <Grid
          rows={fmtGrid(data)}
          columns={columns.filter((e)=> !['region', 'ownership', 'country', 'utility_type', 'ownership', 'utility'].includes(e.key))}
          style={{height: "min-content",
                  minWidth: '100%',
                  width: "min-content",
                  fontFamily: "sans-serif"}}
        />
      </section>
    </Layout>

  );
}
