import React from "react";
import "../styles/global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfoCircle,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import {NavLink} from "react-router-dom";

export default function Navigation() {
  return (
    <nav id="sidebar">
      <NavLink
        to="/"
        title="Visit the home page"
        className="nav-items nav-link"
        key='nav-home'
      >
        <FontAwesomeIcon
          icon={faHome}
          className="other-icons"
        />
      </NavLink>
      <NavLink
        to="/indicators/"
        className="nav-items nav-link"
        key='nav-indicators'
        title="View Indicator Graphs"
      >
        <img src="/~/../static/img/Financial.svg" className="other-icons" />
      </NavLink>
      <NavLink
        to="/utilities/"
        className="nav-items nav-link"
        key='nav-utilities'
        title="View Utility Dashboards"
      >
        <img src="/~/../static/img/Globe.svg" className="other-icons" />
      </NavLink>
      <NavLink
        to="/about"
        className="nav-items nav-link"
        key='nav-about'
        title="About the site and Methodology"
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="other-icons"
        />
      </NavLink>
      <NavLink
        to="/download"
        className="nav-items nav-link"
        key='nav-download'
        title="Download data"
      >
        <FontAwesomeIcon
          icon={faDownload}
          className="other-icons"
        />
      </NavLink>
    </nav>
  );
}
