import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <footer id="footer-container">
      <Container>
        <p className="text-center">
          &copy; <span>{currentYear}</span> Energy Sector Management Assistance Program. All rights reserved.
        </p>
      </Container>
    </footer>
  );
}
