import React, { useContext, useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import NotFound from "./NotFound";

import LayoutWithInnerSidebar from "../components/LayoutWithInnerSidebar";
import ContentSidebar from "../components/ContentSidebar";
import ContentMainPage from "../components/ContentMainPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown, faGrip } from "@fortawesome/free-solid-svg-icons";

import {DataContext} from "../components/DataContext";
import {PerformanceChart} from "../components/PerformanceChart";


import {fmtCountry, fmtRegion, fmtIndicator, fmtGrid} from "../components/format";

import { Grid } from '../components/Grid';

import { graphIndicatorPrefs } from '../components/prefs';

import {
  NavLink,
} from "react-router-dom";

const indicator_sidebar_menu = {
  "Capital Structure": {
    id:"capital-structure",
    icon: "Operational.svg",
    data: [],
  },
  "Cost Recovery": {
    id:"cost-recovery",
    icon: "Financial.svg",
    data: [],
  },
  "Liquidity": {
    id:"liquidity",
    icon: "Financial.svg",
    data: [],
  },
  "Profitability": {
    id:"profitability",
    icon: "Financial.svg",
    data: [],
  },
  "Subsidies and resource extraction": {
    id:"subsidies",
    icon: "Financial.svg",
    data: [],
  },
  "Reliability": {
    id:"reliability",
    icon: "Financial.svg",
    data: [],
  },
  "Efficiency": {
    id:"efficiency",
    icon: "Financial.svg",
    data: [],
  },
};

Object.entries(graphIndicatorPrefs).forEach(([indicator_slug, data]) => {
  indicator_sidebar_menu[data.category].data.push({...data, indicator_slug});
});

const sidemenu_keys = Object.keys(indicator_sidebar_menu);

export default function Indicators({indicatorSlug, grid}) {

  console.log(graphIndicatorPrefs);
  const currentIndicatorPrefs = graphIndicatorPrefs[indicatorSlug];
  if (!currentIndicatorPrefs) {
    return (<NotFound/>);
  }
  const indicatorName = currentIndicatorPrefs.indicator_display;

  const dataLayer = useContext(DataContext);

  const [indicators, setIndicators] = useState([]);

  const [utility, setUtility] = useState('');
  const [utilities, setUtilities] = useState([]);
  const [context, setContext] = useState('all');

  const [data, setData] = useState([]);
  const [medians, setMedians] = useState([]);
  const [db_init, setDbInit] = useState(false);
  const [columns, setColumns] = useState([]);
  const [toggleBoxPlot, setToggleBoxPlot] = useState("box");

  // default to current selection
  const [openSection, setOpenSection] = useState(currentIndicatorPrefs.category);


  const onChangeToggle = () => {
    setToggleBoxPlot((prevState) => (prevState === "box" ? "" : "box"));
  };



  useEffect(() => {
    if (!db_init) {
      if (dataLayer.isDbInit()) {
        setDbInit(true);
        dataLayer.getColumns(setColumns);
      } else {
        dataLayer.init_db(setDbInit, setColumns);
        return;
      }
    }

    const _util = async () => {
      const _data = await dataLayer.query(`
           select distinct country, utility, utility_short_display, utility_type, region
           from data
           where indicator=?
           order by country, utility`, [indicatorName]);
      if (_data){
        setUtilities(_data);
      }
    };

    const _f = async () => {
      const _data = await dataLayer.getData(indicatorName, context, utility);
      console.log(_data[0]);
      setData(_data);
    };

    const _m = async () => {
      const _data = await dataLayer.getMedians(indicatorName, context, utility);
      console.log(_data[0]);
      setMedians(_data);
    };

    const _grid = async () => {
      if (!columns.length) {
        await dataLayer.getColumns(setColumns);
      }
      const _data = await dataLayer.getGridData(indicatorName, context, utility);
      console.log(_data[0]);
      setData(_data);
    };


    _util().then(() => {
      if (grid === undefined) {
        _f().then(_m);
      } else {
        _grid();
      }});

  }, [indicatorName, grid, context, utility, db_init, columns]);


  const toggleSection = (sectionId) => {
    setOpenSection((prevOpenSection) => (prevOpenSection === sectionId ? null : sectionId));
  };

  const utilityInfo = utilities.filter((e)=>e.utility==utility).pop() || {};
  const gridExt = grid ? "/grid":'';

  return (
    <LayoutWithInnerSidebar>
      <ContentSidebar>
        <header className="ps-4 pe-4 mt-4 mb-5">
          <h3 className="fw-bold">
            Explore <br />Indicators
          </h3>
        </header>

        <div id="indicator-nav">
        {sidemenu_keys.map((element) => (
          <section key={element} className="collapsed-nav">
            <header id={indicator_sidebar_menu[element].id} className="ps-4 pe-4"
            onClick={() => toggleSection(element)}
            style={{ cursor: 'pointer' }} >
              {openSection === element ? (
              <>
                <div className="d-flex justify-content-between">
                <h6 className="fw-bold">{element}</h6>
                <FontAwesomeIcon icon={faCaretUp} className="ms-auto" />
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between">
              <h6 className="fw-bold">{element}</h6>
              <FontAwesomeIcon icon={faCaretDown} className="ms-auto" />
              </div>
            )}
            </header>
            <ul
              id={indicator_sidebar_menu[element].id+"-list"}
              className={`inner-navs list-decoration-none mt-3 ${openSection === element ? "" : "d-none"}`}
            >
              {indicator_sidebar_menu[element].data.map(({indicator_slug, indicator_display}) => (
                <li key={indicator_slug}>
                  <NavLink
                    to={`/indicators/${indicator_slug}${gridExt}`}
                    className="nav-link inner-nav-items"
                  >
                    {indicator_display}
                  </NavLink>
                </li>
              ))}
            </ul>
          </section>
        ))}
        </div>
      </ContentSidebar>

      <ContentMainPage>
        <header className="mb-3">
          <h5 className="fw-bold">{indicatorName}</h5>
        </header>

        <article className="w-75">
          <p>
            {currentIndicatorPrefs.indicator_blurb}
          </p>
        </article>

        <section
          id="graph-action-options"
          className="d-flex justify-content-between mt-3"
        >
          {(grid === undefined) ? (
          <div className="w-50">
            <form className="d-flex gap-4">
              <div>
                <label htmlFor="utility-highlight-filter" className="col-form-label">
                  Highlight Utility:
                </label>
                <select
                  id="utility-highlight-filter"
                  className="form-select"
                  aria-label="Choose a utility to highlight in the graph"
                  onChange={e=>setUtility(e.target.value)}
                  value={utility.utility}
                >
                  <option key="None" value=''>---</option>
                  {utilities.map((val, ix)=>{return <option key={ix} value={val.utility}>{fmtCountry(val.country)} -- {val.utility_short_display}</option>;})}
                </select>
              </div>

              <div>
                <label htmlFor="utility-context-filter" className="col-form-label">
                  Graph Context:
                </label>
                <select
                  id="utility-context-filter"
                  className="form-select"
                  aria-label="Select the context for which values to show in addition to the chosen utility"
                  onChange={e=>setContext(e.target.value)}
                  value={!utility ? 'all': context}
                  disabled={!utility}
                >
                  {Object.entries({all:'All Utilities',
                                   country:`Utilities in ${fmtCountry(utilityInfo.country)}`,
                                   utility_type:`Utilities of the Same Type (${utilityInfo.utility_type})`,
                                   region:`Utilties in the Same Region (${utilityInfo.region})`}
                                 ).map(([val,title], ix) =>
                                     {return <option key={`context-${val}`} value={val}>{title}</option>;})}
                </select>
              </div>
            </form>
          </div>
          ) : <div></div>
        }
          <div className="align-self-end">
            {(grid === undefined) ? (
              <NavLink
              to={`/indicators/${indicatorSlug}/grid`}
              className="btn btn-yellow"
            >
              Table View
              <img src="/static/img/Grid.svg" className="ms-2 btn-icons" />
              </NavLink>
            ) : (
               <NavLink
              to={`/indicators/${indicatorSlug}`}
              className="btn btn-yellow"
            >
              Graph View
              <img src="/static/img/Graph.svg" className="ms-2 btn-icons" />
              </NavLink>
             )}

            <a
              className="btn btn-navy-blue ms-3"
              title="Download as CSV"
              onClick={e=>dataLayer.csvDownload(indicatorName, utility, context)}
            >
              Download CSV <FontAwesomeIcon icon={faGrip} className="ms-2" />
            </a>
          </div>
        </section>

        {(grid === undefined) ? (
          <section id="graph">
            <div className="toggle-boxblot">
                <input
                type="checkbox"
                checked={toggleBoxPlot === "box"}
                onChange={onChangeToggle}
                className="me-1"
                />
              Show Box Plot
            </div>

            <PerformanceChart
              data={data}
              medians={medians}
              indicatorName={indicatorName}
              selectedUtility={utility}
              medianStyle={toggleBoxPlot}
              prefs={currentIndicatorPrefs}
            />
          </section>
        ) : (
          <section id="grid">
            <Grid
              rows={fmtGrid(data)}
              columns={columns.filter((e)=> !['region', 'ownership', 'indicator'].includes(e.key))}
              style={{height: "min-content",
                      minWidth: '100%',
                      width: "min-content",
                      fontFamily: "sans-serif"}}
            />
          </section>
        )
        }
      </ContentMainPage>
    </LayoutWithInnerSidebar>
  );
}
