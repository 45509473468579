import dashboardIndicatorsCsv from "bundle-text:/src/static/data/utility_indicators.csv";
import graphIndicatorsCsv from "bundle-text:/src/static/data/graph_indicators.csv";
import { parse as csvParse } from "csv-parse/lib/sync";


const parse = (csv, options = {}) =>
  csvParse(csv, { columns: true, cast: true, ...options });

export const graphIndicatorPrefs = Object.fromEntries(
  parse(graphIndicatorsCsv)
    .map(({ indicator_display_slug, indicator, ...rest }) => [indicator_display_slug, rest])
  // indicator is the old, transformed away name of the indicator
);

export const dashboardIndicatorsList = parse(dashboardIndicatorsCsv);

export const indicatorFormatMapping = Object.fromEntries(
  [...Object.values(graphIndicatorPrefs).map(({indicator_display, digits})=>[indicator_display, digits]),
   ...dashboardIndicatorsList.map(({indicator_display, digits})=>[indicator_display, digits])]);
