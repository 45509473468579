
export const Grid = ({
  rows,
  columns,
  style
}) => (
  <table
    style={style}>
    <thead>
      <tr>
        {columns.map(({name}) => (
          <th>{name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((r) => (
        <tr>
          {columns.map(({key})=>(
            <td>
              {r[key]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
